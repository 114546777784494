function ContinueToBuyGiftPopup({ onConfirm, closePopup }) {
  return (
    <div className="popup-model"
    style={{minHeight: '120px !important', minWidth: '120px'}}>
<div
  className="popup-container"

>
      <button
          onClick={closePopup}
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ✖
        </button>
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.5rem", color: "green" }}>התחברת בהצלחה, מעבירים אותך להמשך קנית מתנה</h1>
          <div style={{ marginTop: "2rem", width: '63%' }}>
            <button>אישור</button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ContinueToBuyGiftPopup;