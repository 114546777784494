function RemoveProductPopup({ onConfirm, onCancel }) {
  return (
    <div className="popup-model"
    style={{minHeight: '120px !important', minWidth: '120px'}}>
      <div className="popup-container">
      <button
          onClick={onCancel}
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ✖
        </button>
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1rem", color: "green" }}>האם אתה בטוח שאתה רוצה לוותר על מוצר זה?</h1>
          <div style={{ marginTop: "2rem", width: '63%' }}>
            <button
              onClick={onConfirm} // Call confirm function
              className="btn"
              style={{
                marginBottom: "0.7rem",
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
              אישור
            </button>
            <button
              onClick={onCancel} // Call cancel function
              className="btn btn-orange"
              style={{
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
            ביטול
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveProductPopup;