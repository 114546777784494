import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import { topbarBgGreen } from "../Assets/images";

import {
  validateTextInput,
  validateEmailInput,
  validateFixedCountInput,
  validatePhoneNumber,
  inputMaxCount,
  clearError,
  validateFullPhoneNumber,
} from "../Libs/forms";
import { NavLink, useNavigate } from "react-router-dom";
import { citiesText } from "../Libs/citiesText";
import { useClickOutsideMulti } from "../hooks/useClickOutsideMulti";
//import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import profImgPlaceholder from '../Assets/img/profile/avatar-placeholder.webp'

let streetsArr = null,
  citiesArr = null;

function EditAddressPopup({
  closeShowEditAdressPopup,
  currUserObject,
  setCurrUserObject
}) {
  const navigate = useNavigate();


  const [profileInfo, setProfileInfo] = useState({
    fullname: "",
    phoneNumber: "",
    // telSecond: "",
    birthDay: "",
    birthMonth: "",
    birthdayYear: "",
    email: "",
    city: "",
    street: "",
    building: "",
    // addressHouse: "",
    addressApartments: "",
    addressEntrance: "",
    postal: "",
    mailbox: "",
    profileImg: currUserObject?.user?.userPic
  });

  const [citiesObject, setCitiesObject] = useState([]);
  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [selectedDate, setSelectedDate] = useState({
    date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
    day: profileInfo.birthDay,
    month: profileInfo.birthMonth,
    year: profileInfo.birthdayYear,
  });
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);

  console.log(profileInfo, 'profileInfo')

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) return;
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      // Send the image data to the API
      const response = await axios.post(`${API_DOMAIN}/api/ProductCategory/upload`, formData);

      // Handle the API response here if needed
      console.log("Image uploaded successfully", response.data);

      // Update the profile image URL in your state
      const imageURL = response.data;
      setProfileInfo((prev) => ({ ...prev, profileImg: imageURL }));

      // Reset the input value to clear the selection
      e.target.value = "";
    } catch (error) {
      // Handle any errors that occurred during the upload
      console.error("Error uploading image", error);
    }
  };
  const goToCartByWishlist1 = () => {
    debugger;
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
        //  setCurrWishlist({
        //    whishlistItemsList: response.data.whishlistItemsList,
          //  whishlistObject: response.data.whishlistObject,
            //whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          //});

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
    //    alert("Error, please try again");
      });

  };

  const handleDateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    setSelectedDate({
      date: result.date,
      day: result.day,
      month: result.month,
      year: result.year,
    });
  };
  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value !== "") {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    } else {
      const date = "";
      const day = "";
      const month = "";
      const year = "";
      return { date, day, month, year };
    }
  };

  const submitProfileForm = (e) => {
    e.preventDefault();

    const form = e.target;

    const fullnameInput = form["fullname"];
    const telInputFirst = form["tel-1"];
    // const telInputSecond = form["tel-2"];
    const emailInput = form["email"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];
    const cityInput = form["city"];
    const streetInput = form["street"];
    const buildingInput = form["house-num"];
    const addressApartmentInput = form["apartment-num"];
    const addressEntranceInput = form["entrance"];
    const mailboxInput = form["mailbox"];
    const postalInput = form["postal-code"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelFirst = validateFixedCountInput(telInputFirst, 7);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isEmail = validateEmailInput(emailInput);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);
    const isCity = validateTextInput(cityInput, 1);
    const isStreet = validateTextInput(streetInput, 1);
    const isBuilding = validateTextInput(buildingInput, 1);
 //   const isAddressApartment = validateTextInput(addressApartmentInput, 1);
 //   const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
    // const isMailboxInput = validateTextInput(mailboxInput, 1);
    //const isPostalInput = validateTextInput(postalInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isEmail &&
      isCity &&
      isStreet &&
      isBuilding// &&
    //  isAddressApartment
      // &&
      //isAddressEntrance &&
      // isMailboxInput &&
    //  isPostalInput;

    if (isFormValid) {
      const result = {
        email: emailInput.value,
        firstName: fullnameInput.value.split(" ")[0],
        lastName: fullnameInput.value.split(" ")[1],
        phoneNumber: telInputFirst.value,
        companyName: currUserObject.user.companyName,
        bankAccountId: currUserObject.user.bankAccountId,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        birthdate:
          birthdayInputDay.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputYear.value,
        entranceNumber: addressEntranceInput.value,
        apartmentNumber: addressApartmentInput.value,
        houseNumber: buildingInput.value,
        userId: currUserObject.user.id,
        city: cityInput.value,
        street: streetInput.value,
        userPic: profileInfo.profileImg,

        // isRemember: form["isRemember"].checked,
      };

      axios
        .post(
          `${API_DOMAIN}/api/Authenticate/UpdateUserDetails`,
          result
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {

            debugger;
            console.log("data that we have got", response.data);
             setCurrUserObject(response.data);
             localStorage.setItem(
               "currUserObject",
               JSON.stringify(response.data)
            );
            setCurrUserObject(response.data);
            localStorage.setItem(
              "currUserObject",
              JSON.stringify(response.data)
            );
            alert("הפרטים שלך נשמרו!");
          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
       //   alert("Error, please try again");
        });

      console.log(result);
    }
  };


  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };
  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };
  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
  };

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();
  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);
    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    // clearError(document.getElementById("form-credentials-section__city"));
  }
  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    // clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  useEffect(() => {
    const ourObject = parseCSVData(citiesText);
    console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    console.log("streetsDistinctList", uniqueStreets);
  }, []);
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  useEffect(() => {
    if (!currUserObject) {
      navigate("/credentials");
    } else {
      console.log("our current user", currUserObject.user);
      setProfileInfo({
        fullname: currUserObject.user?.firstName + " " + currUserObject.user?.lastName,
        phoneNumber: currUserObject.user?.phoneNumber,
        // telSecond: currUserObject.user.phoneNumber.slice(0, 3),
        birthDay: currUserObject.user?.birthdate
          ? currUserObject.user?.birthdate.slice(8, 10)
          : "",
        birthMonth: currUserObject.user?.birthdate
          ? currUserObject.user.birthdate.slice(5, 7)
          : "",
        birthdayYear: currUserObject.user?.birthdate
          ? currUserObject.user?.birthdate.slice(0, 4)
          : "",
        email: currUserObject.user?.email,
        city: currUserObject.user?.city,
        street: currUserObject.user?.street,
        building: currUserObject.user?.building,
        // addressHouse: currUserObject.user.houseNumber,
        addressApartments: currUserObject.user?.apartmentNumber,
        addressEntrance: currUserObject.user?.entranceNumber,
        postal: currUserObject.user?.postal,
        mailbox: currUserObject.user?.mailBox,
        houseNumber: currUserObject.user?.houseNumber,
        profileImg: currUserObject.user?.userPic
      });
      setSelectedCity(currUserObject.user?.city);
      setSelectedStreet(currUserObject.user?.street);
    }
  }, []);
  useEffect(() => {
    setSelectedDate({
      date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
      day: profileInfo.birthDay,
      month: profileInfo.birthMonth,
      year: profileInfo.birthdayYear,
    });
  }, [profileInfo]);

  return (
    <div
      className="popup_show"
      style={{
        margin: '17vh auto',
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
     //   backgroundColor: "rgba(0,0,0,0.5)",
        overflowY: "none"

      }}
    >
      <div
        style={{
          maxWidth: "26.927083vw",
          margin: "5rem auto",
          borderRadius: "0.9375rem",
          background: "#fff",
          padding: "2rem",
          position: "relative",
          minHeight: 'none'
        }}
      >
        {/* Close button */}
        <button
          type="button"
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            fontSize: "1.2rem"
          }}
          onClick={closeShowEditAdressPopup}
        >
          ✕
        </button>
        <main className="edit-profile" style={{paddingTop: '0'}}>
     
     

      <section className="edit-profile__main main-edit-profile" style={{paddingTop: '0', paddingBottom: '0'}}>
        <div className="main-edit-profile__container">

          <div className="main-edit-profile__form-block">

           

            <form
              action="#"
              onSubmit={submitProfileForm}
              className="main-edit-profile__form form-edit-profile"
            >
              <div className="form-edit-profile__form-body" style={{marginBottom: '0'}}>
           
                  
                <div className="form-edit-profile__col" style={{flex: '100%'}}>
                  {/* <label
                    htmlFor="form-edit-profile__address"
                    className="form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">כתובת מגורים</span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="address"
                      id="form-edit-profile__address"
                      className="input"
                      defaultValue={profileInfo.city}
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 1);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 1 char</span>
                  </label> */}
                  <div
                    ref={citiesLabelRef}
                    // htmlFor="form-credentials-section__address"
                    className="form-edit-profile__input-wrapper input-wrapper label"
                  >
                    <span className="input-title">עיר</span>
                    <button
                      type="button"
                      onClick={handleCitiesOpen}
                      className="city-street-select-placeholder"
                    ></button>
                    <input
                       style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}
                      autoComplete="off"
                      type="text"
                      name="city"
                      id="form-edit-profile__city"
                      className="input input-address"
                      // defaultValue={registerFormInfo.address}
                      value={selectedCity}
                    />
                    {formCitiesOpen && (
                      <>
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                                                   style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-1"
                              aria-autocomplete="list"
                              aria-activedescendant="bs-select-1-66"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  citiesLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {citiesArr.map((city, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => citySelectHandler(city)}
                              >
                                <span className="text">
                                  {city["city_name"]}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    <span className="error-text">You must select city</span>
                  </div>
                  {/* <p className="form-title">כתובת</p> */}
                  <div className="street-wrapper">
                    <div
                      ref={streetsLabelRef}
                      className="form-edit-profile__input-wrapper input-wrapper street label"
                    >
                      <span className="input-title">רחוב</span>

                      <button
                        type="button"
                        onClick={handleStreetsOpen}
                        className="city-street-select-placeholder"
                      ></button>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="text"
                        name="street"
                        id="form-edit-profile__street"
                        className="input"
                        value={selectedStreet}
                      />
                      {selectedCity && formStreetsOpen && (
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                                                   style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-2"
                              aria-autocomplete="list"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  streetsLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {streets.map((street, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => streetSelectHandler(street)}
                              >
                                <span className="text">
                                  {/* {street["street_name"]} */}
                                  {street}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <span className="error-text">חובה לבחור רחוב</span>
                    </div>
                  </div>
                  <div className="form-edit-profile__address-details" style={{marginTop: '1rem'}}>
                    <label
                      htmlFor="form-edit-profile__house-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מס' בית</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="house-num"
                        id="form-edit-profile__house-num"
                        className="input"
                        defaultValue={profileInfo.houseNumber}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">לפחות תו 1</span>
                    </label>
                    <label
                      htmlFor="form-edit-profile__apartment-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">דירה</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="apartment-num"
                        id="form-edit-profile__apartment-num"
                        className="input"
                        defaultValue={profileInfo.addressApartments}
                     
                      />
                    </label>
                    <label
                      htmlFor="form-edit-profile__entrance"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">כניסה</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="text"
                        name="entrance"
                        id="form-edit-profile__entrance"
                        className="input"
                        defaultValue={profileInfo.addressEntrance}
               
                      />
                    </label>
                  </div>
                  <div className="form-edit-profile__mail" style={{marginTop: '0.4rem'}}>
                    <label
                      htmlFor="form-edit-profile__mailbox"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">ת.ד</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="mailbox"
                        id="form-edit-profile__mailbox"
                        className="input"
                        defaultValue={profileInfo.mailbox}
                
                      />
                    </label>
                    <label
                      htmlFor="form-edit-profile__postal-code"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מיקוד</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="postal-code"
                        id="form-edit-profile__postal-code"
                        className="input"
                        defaultValue={profileInfo.postal}
                    
                      />
                    </label>
                  </div>
                  <div className="form-edit-profile__checkbox checkbox">
                    <input
                      id="c_1"
                      className="checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      name="isRemember"
                    />
                   
                  </div>
                </div>
                <div className="form-edit-profile__col" style={{visibility: 'hidden', width: '0.1px', flex:'none'}}>
                 
                 <input
                   type ="hidden"
                   autoComplete="off"
                   name="fullname"
                   id="form-edit-profile__fullname"
                   className="input"
                   defaultValue={profileInfo.fullname}
                   onInput={(e) => {
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       validateTextInput(e.target, 3);
                     }
                   }}
                 />
     
                 <input
                   autoComplete="off"
                   type="hidden"
                   name="tel-1"
                   id="form-edit-profile__tel-1"
                   defaultValue={profileInfo.phoneNumber}
                   className="input"
                   onInput={(e) => {
                     inputMaxCount(e.target, 10);
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       // validateFixedCountInput(e.target, 7);
                       validateFullPhoneNumber(e.target);
                     }
                   }}
                 />
           
        
                 <input
                   autoComplete="off"
                   type="hidden"
                   name="email"
                   defaultValue={profileInfo.email}
                   id="form-edit-profile__email"
                   className="input"
                   onInput={(e) => {
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       validateEmailInput(e.target);
                     }
                   }}
                 />
       
                   <input
                     type="hidden"
                     className="birthday-field__hidden-main-field"
                     value={selectedDate.date}
                     onChange={(e) => {
                       handleDateChange(
                         e.target,
                         dayRef.current,
                         monthRef.current,
                         yearRef.current
                       );
                     }}
                   />
          
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-year"
                       id="form-edit-profile__birthday-year"
                       className="input"
                       ref={yearRef}
                       value={selectedDate.year}
                       onChange={(event) => { }}
                     />
                    
                
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-month"
                       id="form-edit-profile__birthday-month"
                       className="input"
                       ref={monthRef}
                       value={selectedDate.month}
                       onChange={(event) => { }}
                     />
        
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-day"
                       id="form-edit-profile__birthday-day"
                       className="input"
                       ref={dayRef}
                       value={selectedDate.day}
                       onChange={(event) => { }}
                     />
                 </div>
              </div>
              <div
                className="form-edit-profile__btn-container btn-container_center"

              >
                <button type="submit" className="btn" style={{marginBottom: '1rem'}}>
                  שמירה
                </button>
              </div>
              <div className="form-edit-profile__btn-container btn-container_center">
                <button
                  type="button"
                  className="btn btn-orange"
                  onClick={() => closeShowEditAdressPopup()}
                >
               ביטול
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      {
        <div className="form-credentials-section__buttons-container mobile">
          <button
            type="submit"
            className="btn footer-mobile"
            style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding: "7px" }}

          >
            <div style={{ flex: 1 }}>
              <NavLink
                to="/"
              >
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.516"
                    height="25.445"
                    viewBox="0 0 25.516 25.445"
                  >
                    <path
                      id="home_1_"
                      data-name="home (1)"
                      d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                      transform="translate(0.771 0.601)"
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1.2"
                    />
                  </svg>
                </div>
                <p className="text1">ראשי</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink to="/contact-us" >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height={26}
                    viewBox="0 0 27 26"
                  >
                    <g
                      id="Сгруппировать_1499"
                      data-name="Сгруппировать 1499"
                      transform="translate(1177 -2115)"
                    >
                      <g
                        id="Эллипс_411"
                        data-name="Эллипс 411"
                        transform="translate(-1177 2115)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_412"
                        data-name="Эллипс 412"
                        transform="translate(-1159 2122)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_413"
                        data-name="Эллипс 413"
                        transform="translate(-1175 2132)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <line
                        id="Линия_524"
                        data-name="Линия 524"
                        x2={10}
                        y2={4}
                        transform="translate(-1168.5 2120.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                      <line
                        id="Линия_525"
                        data-name="Линия 525"
                        y1={6}
                        x2={9}
                        transform="translate(-1166.5 2128.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">צרו קשר</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                to="/my-bank"
              >
                <span className="icon">
                  <svg
                    id="Artboard_1"
                    data-name="Artboard 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.901"
                    height="24.498"
                    viewBox="0 0 23.901 24.498"
                  >
                    <path
                      id="Контур_10114"
                      data-name="Контур 10114"
                      d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                      transform="translate(6.338 -33.519)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10115"
                      data-name="Контур 10115"
                      d="M16,54h.891v.891H16V54Z"
                      transform="translate(6.12 -35.737)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10116"
                      data-name="Контур 10116"
                      d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                      transform="translate(-15.361 -30.746)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10117"
                      data-name="Контур 10117"
                      d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                      transform="translate(-21 -29.083)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2304"
                      data-name="Прямоугольник 2304"
                      width="1.4"
                      height="2.8"
                      transform="translate(7.107 2.8)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2305"
                      data-name="Прямоугольник 2305"
                      width="0.7"
                      height="2.8"
                      transform="translate(9.207)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2306"
                      data-name="Прямоугольник 2306"
                      width="0.7"
                      height="2.8"
                      transform="translate(13.407 8.399)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2307"
                      data-name="Прямоугольник 2307"
                      width="1.4"
                      height="1.4"
                      transform="translate(14.807 11.899)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10118"
                      data-name="Контур 10118"
                      d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                      transform="translate(-18.31 -13.535)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10119"
                      data-name="Контур 10119"
                      d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                      transform="translate(-20.664 -14.17)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10120"
                      data-name="Контур 10120"
                      d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                      transform="translate(-42.772 -20.762)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10121"
                      data-name="Контур 10121"
                      d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                      transform="translate(-37.854 -20.969)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2308"
                      data-name="Прямоугольник 2308"
                      width="0.7"
                      height="0.7"
                      transform="translate(9.207 3.5)"
                      fill="#707070"
                    />
                  </svg>
                </span>
                <p className="text1">הקופה שלי</p>

              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
               onClick={() => goToCartByWishlist1()}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.846"
                    height="24.798"
                    viewBox="0 0 19.846 24.798"
                  >
                    <g
                      id="Сгруппировать_74057"
                      data-name="Сгруппировать 74057"
                      transform="translate(0.5 0.5)"
                    >
                      <path
                        id="Artboard_2"
                        data-name="Artboard 2"
                        d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                        transform="translate(-74.992 -18.61)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth={1}
                      />
                      <path
                        id="heart"
                        d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                        transform="translate(-25.26 -15.21)"
                        fill="#ec1b53"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">סל המתנות</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                to="/my-events"
              //  onClick={() => setMyEventsActiveTab(0)}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.959"
                    height="22.842"
                    viewBox="0 0 29.959 22.842"
                  >
                    <g
                      id="Сгруппировать_74063"
                      data-name="Сгруппировать 74063"
                      transform="translate(-14 0.5)"
                    >
                      <g
                        id="Artboard_3"
                        data-name="Artboard 3"
                        transform="translate(22.214 0)"
                      >
                        <path
                          id="Контур_38424"
                          data-name="Контур 38424"
                          d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                          transform="translate(-24.307 -20.873)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38425"
                          data-name="Контур 38425"
                          d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                          transform="translate(-20.77 -14.613)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38426"
                          data-name="Контур 38426"
                          d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                          transform="translate(-14.469 -14.389)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_526"
                          data-name="Линия 526"
                          y2="4.793"
                          transform="translate(9.839 13.419)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_527"
                          data-name="Линия 527"
                          y2="8.147"
                          transform="translate(7.443 11.023)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_528"
                          data-name="Линия 528"
                          y2="11.684"
                          transform="translate(5.047 8.445)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_529"
                          data-name="Линия 529"
                          y2="7.055"
                          transform="translate(2.651 14.033)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <circle
                          id="Эллипс_414"
                          data-name="Эллипс 414"
                          cx="1.438"
                          cy="1.438"
                          r="1.438"
                          transform="translate(10.319)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38427"
                          data-name="Контур 38427"
                          d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                          transform="translate(-27.244 -17.922)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38428"
                          data-name="Контур 38428"
                          d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                          transform="translate(-31.988 -10.775)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38429"
                          data-name="Контур 38429"
                          d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                          transform="translate(-20.474 -9.487)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38430"
                          data-name="Контур 38430"
                          d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                          transform="translate(-28.181 -7.104)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                      <g
                        id="Сгруппировать_1710"
                        data-name="Сгруппировать 1710"
                        transform="translate(14 2.322)"
                      >
                        <g
                          id="Контур_10101"
                          data-name="Контур 10101"
                          transform="translate(0 1.369)"
                          fill="#3c9e1c"
                        >
                          <path
                            d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                            stroke="none"
                          />
                          <path
                            d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                            stroke="none"
                            fill="#fff"
                          />
                        </g>
                        <text
                          id="_"
                          data-name="+"
                          transform="translate(6.845 12.059)"
                          fill="#fff"
                          fontSize="11"
                          fontFamily="Assistant-SemiBold, Assistant"
                          fontWeight="600"
                        >
                          <tspan
                            x="2.783"
                            y="0"
                          >
                            +
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </span>
                <p className="text1">אירוע חדש</p>


              </NavLink>
            </div>

          </button>
        </div>}
    </main>
      </div>
    </div>
  );
}

export default EditAddressPopup;
