import axios from "axios";
import React from "react";
import { NavLink } from "react-router-dom";
import { API_DOMAIN } from "../../Libs/config";
function MyBankSinglePurchase({
  data: {
    wishlistId,
    image,
    amount,
    status,
    productName,
    productPrice,
    productImage,
    ownerName,
    ownerUserId,
    buyerUserId,
    blessingTitle,
    wishListItemId,
    isRemovable,
    manuallAdd
  },
  currUserObject,
  currWishlist,
  setCurrWishlist,
  isGeneral
}) {

  return (
    <div className="my-purchase-single">
      <div className="my-purchase-single__content">
      <div className="my-purchase-single__image-ibg">
        {image && (
  <>
    {manuallAdd === true ? (
      // If manually added, transform the image URL
      <img
        src={`https://server.yyyshopping.com${image.replace(/^,/, "")}`}
      />
    ) : (
      // Otherwise, use the original image URL
      <img src={image} alt="Person" />
    )}
  </>
)}        </div>
        <div className="my-purchase-single__text-content">
          <h2 className="my-purchase-single__name">{productName}</h2>
          
          <p className="my-purchase-single__price">₪{productPrice}</p>
          <div className="my-purchase-single__actions" 

  >
            {/* {isRemovable && (
              <button className="button delete" onClick={() => deleteProduct()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="31.515"
                  height={37}
                  viewBox="0 0 31.515 37"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Прямоугольник_1981"
                        data-name="Прямоугольник 1981"
                        width="29.208"
                        height="25.38"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      />
                    </clipPath>
                    <clipPath id="clip-path-2">
                      <rect
                        id="Прямоугольник_1983"
                        data-name="Прямоугольник 1983"
                        width="18.458"
                        height="8.075"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Trash_can"
                    data-name="Trash can"
                    transform="translate(0 -2.813)"
                  >
                    <g
                      id="Прямоугольник_1980"
                      data-name="Прямоугольник 1980"
                      transform="translate(0 10.354)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeWidth="1.4"
                    >
                      <rect
                        width="31.515"
                        height="4.614"
                        rx="2.307"
                        stroke="none"
                      />
                      <rect
                        x="0.7"
                        y="0.7"
                        width="30.115"
                        height="3.214"
                        rx="1.607"
                        fill="none"
                      />
                    </g>
                    <line
                      id="Линия_491"
                      data-name="Линия 491"
                      x2="2.092"
                      y2="15.061"
                      transform="translate(8.884 17.722)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <line
                      id="Линия_492"
                      data-name="Линия 492"
                      y2="15.061"
                      transform="translate(15.605 17.722)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <line
                      id="Линия_493"
                      data-name="Линия 493"
                      x1="1.935"
                      y2="14.733"
                      transform="translate(19.953 17.703)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <g
                      id="Группа_масок_833"
                      data-name="Группа масок 833"
                      transform="translate(1.154 14.433)"
                      clipPath="url(#clip-path)"
                    >
                      <g
                        id="Контур_8526"
                        data-name="Контур 8526"
                        transform="translate(8.612 -4.365)"
                        fill="none"
                      >
                        <path
                          d="M-5.19,0H16.826a1.918,1.918,0,0,1,1.81,2.011L16.524,26.479a1.918,1.918,0,0,1-1.81,2.011H-3.079a1.918,1.918,0,0,1-1.81-2.011L-7,2.011A1.918,1.918,0,0,1-5.19,0Z"
                          stroke="none"
                        />
                        <path
                          d="M -5.190471649169922 1.399999618530273 C -5.375249862670898 1.399999618530273 -5.580936431884766 1.638710021972656 -5.598752975463867 1.965187072753906 L -3.488882064819336 26.41834831237793 L -3.488882064819336 26.4786376953125 C -3.488882064819336 26.82835960388184 -3.272671699523926 27.08967971801758 -3.079351425170898 27.08967971801758 L 14.71437835693359 27.08967971801758 C 14.9076976776123 27.08967971801758 15.1239185333252 26.82835960388184 15.1239185333252 26.4786376953125 L 15.1239185333252 26.41834831237793 L 17.23379135131836 1.965187072753906 C 17.21597290039062 1.638711929321289 17.01027488708496 1.399999618530273 16.82549858093262 1.399999618530273 L -5.190471649169922 1.399999618530273 M -5.190471649169922 -1.9073486328125e-06 L 16.82549858093262 -1.9073486328125e-06 C 17.82487869262695 -1.9073486328125e-06 18.63503837585449 0.9003696441650391 18.63503837585449 2.011037826538086 L 16.52391815185547 26.4786376953125 C 16.52391815185547 27.58930969238281 15.71375846862793 28.48967933654785 14.71437835693359 28.48967933654785 L -3.079351425170898 28.48967933654785 C -4.07872200012207 28.48967933654785 -4.888881683349609 27.58930969238281 -4.888881683349609 26.4786376953125 L -7.000001907348633 2.011037826538086 C -7.000001907348633 0.9003696441650391 -6.189842224121094 -1.9073486328125e-06 -5.190471649169922 -1.9073486328125e-06 Z"
                          stroke="none"
                          fill="#3c9e1c"
                        />
                      </g>
                    </g>
                    <g
                      id="Группа_масок_834"
                      data-name="Группа масок 834"
                      transform="translate(6.529 2.813)"
                      clipPath="url(#clip-path-2)"
                    >
                      <g
                        id="Прямоугольник_1982"
                        data-name="Прямоугольник 1982"
                        transform="translate(0 3.461)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      >
                        <rect
                          width="17.304"
                          height="6.922"
                          rx="3.461"
                          stroke="none"
                        />
                        <rect
                          x="0.7"
                          y="0.7"
                          width="15.904"
                          height="5.522"
                          rx="2.761"
                          fill="none"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            )} */}
            <div  className="my-purchase-single__order-status">
              <span className="my-purchase-single__price">
                {status === 'processing' && 'חדשה'}
                {status === 'pending' && 'בהמתנה'}
                {status === 'shipped' && 'נשלחה'}
                {status === 'delivered' && 'נמסרה'}
              </span>
            </div>

        {/*    {isGeneral &&
            <div  className="my-purchase-single__order-status"  style={
              isGeneral
                ? { marginRight: '4vw' }
                : {}
            }>
              <span className="my-purchase-single__price">
                {ownerUserId === buyerUserId ? 'שלי' : ownerName}
              </span>
            </div>
            }*/}

            {/* <NavLink
              to={"/choose-present-or-send-money/" + wishlistId}
              className="button favorites"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height="41.674"
                viewBox="0 0 40 41.674"
              >
                <g
                  id="Компонент_354_1"
                  data-name="Компонент 354 – 1"
                  transform="translate(0 0.75)"
                >
                  <g
                    id="Сгруппировать_1567"
                    data-name="Сгруппировать 1567"
                    transform="translate(1 5.924)"
                    opacity={0}
                  >
                    <path
                      id="Контур_7916"
                      data-name="Контур 7916"
                      d="M9.412,0H30.588A9.412,9.412,0,0,1,40,9.412V30.588A9.412,9.412,0,0,1,30.588,40H9.412A9.412,9.412,0,0,1,0,30.588V9.412A9.412,9.412,0,0,1,9.412,0Z"
                      transform="translate(-1 -5)"
                      fill="#ec1b53"
                    />
                  </g>
                  <path
                    id="heart"
                    d="M31.193,33.241l6.359,6.426,6.83-6.9a4.577,4.577,0,0,0,0-6.425,4.462,4.462,0,0,0-6.357,0l-.47.476-.471-.476a4.462,4.462,0,0,0-6.357,0,4.577,4.577,0,0,0,0,6.425l.469.473Z"
                    transform="translate(-20.781 -11.381)"
                    fill="#ec1b53"
                    stroke="#ec1b53"
                    strokeMiterlimit={10}
                    strokeWidth="1.5"
                  />
                  <path
                    id="Artboard_2"
                    data-name="Artboard 2"
                    d="M89.1,18.61a10.28,10.28,0,0,1,1.724.672,4.089,4.089,0,0,1,2.026,3.594c.02.946,0,1.893,0,2.882h3.765a2.408,2.408,0,0,1,2.541,2.232q.809,6.016,1.607,12.033c.446,3.359.9,6.717,1.339,10.078a2.383,2.383,0,0,1-1.9,2.735.614.614,0,0,0-.118.055H77.046a2.708,2.708,0,0,1-1.6-1.017,2.3,2.3,0,0,1-.439-1.642q.632-4.8,1.277-9.609.58-4.389,1.166-8.785c.179-1.339.35-2.679.536-4.01a2.392,2.392,0,0,1,2.321-2.062c1.182-.022,2.364-.007,3.547-.008.421-.15.421-.278.421-.406a28.955,28.955,0,0,1,.063-3.04,4.186,4.186,0,0,1,3.536-3.644.928.928,0,0,0,.152-.058Z"
                    transform="translate(-71.492 -18.61)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </NavLink> */}
            <div
              className={`status ${status === "buy by me"
                  ? "red"
                  : status === "buy by buyer"
                    ? "green"
                    : status === "not buy"
                      ? "gray"
                      : ""
                }`}
            >
              <p>
                {status === "buy by me"
                  ? "רכשתי"
                  : status === "buy by buyer"
                    ? "חבר רכש"
                    : status === "not buy"
                      ? "טרם נרכש"
                      : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyBankSinglePurchase;
