import React, { useEffect, useRef, useState } from "react";
import {NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import useClickOutside from "../hooks/useClickOutside";
import TooFarDate  from "./tooFarDate";

import {
  blessingImg,
  blessingImgNoPeople,
  chooseAppBgImage,
  chooseAppBgImageNoPeople,
  mainBgImage,
  mainBgImageNoPeople,
  mainDecor,
  mobileTitleIconBlessing,
  prevBtnImage,
} from "../Assets/images";

import {
  validateTextInput,
  validateEventDate,
  inputMaxCount,
  validateFixedCountInput,
  validateEmailInput,
  clearError,
  validatePhoneNumber,
  validateFullPhoneNumber,
} from "../Libs/forms";
import axios from "axios";
import { useClickOutsideMulti } from "../hooks/useClickOutsideMulti";
import { citiesText } from "../Libs/citiesText";
import AddSmsPhones from "./AddSmsPhones";
import { faL } from "@fortawesome/free-solid-svg-icons";

let streetsArr = null,
  citiesArr = null;

function CreateFutureEventPopup({
  currUserObject,
  currWishlist,
  setCurrWishlist,
  secondStep,
  languageToggle,
  language,
  toggleCreatePopup,
  eventData,
  setFilterObject,
  createPopupRef,
  handleClose,
  isLoggedIn,
  setMyEventsActiveTab,
  wishlistCategories,
  getWishlistList
}) {


  const navigate = useNavigate();

  const location = useLocation();
  const params = location.state == null ? {} : location.state;
  console.log(params, "params");
  localStorage.setItem("eventData", JSON.stringify(eventData))
  const whishlistId = params?.wishListId;
  console.log("whishlistId", whishlistId);
  const [isCookieEvent, setIsCookieEvent] = useState(false)
  const [isDecor, setDecor] = useState(true);
  const [showFarDatePopup, setShowFarDatePopup] = useState(false);
  const [isGray, setIsGray] = useState(false);
  const [dateFar,setDateFar] = useState(false);
  const [createEventStep, setCreateEventStep] = useState(0);
  const [showDatePassed, setShowDatePassed] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [progress, setProgress] = useState(5);
  const [mainBackgroundgImage, setMainBackgroundgImage] = useState({
    main: API_DOMAIN_IMG + eventData.categoryImage,
    noPeople: mainBgImageNoPeople,
  });

  const [selectedEventType, setSelectedEventType] = useState();
  const [selectedValues, setSelectedValues] = useState([]);

  const handleEventTypeChange = (event) => {
    const selectedCategory = wishlistCategories.find(
      (category) => category.categoryName === event.target.value
    );
    setSelectedEventType(selectedCategory);
  };
  
  
  const [step0Info, setStep0Info] = useState({
    fullname: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
  });
  const [step1Info, setStep1Info] = useState({
    fullname2: "",
    address: "",
    telFirst: "",
    telSecond: "",
    city: "",
    street: "",
    addressHouse: "",
    addressApartment: "",
    addressEntrance: "",
    mailbox: "",
    postal: "",
    time: "",
  });
  const [urlWishListId, setUrlWishListId] = useState();
  const [isBirthdayEvent, setIsBirthdayEvent] = useState(eventData?.englishName?.includes('birthday'))
  const urlParams = useParams();
  const [selectedYear, setSelectedYear] = useState('');
  const [buttonText, setButtonText] = useState('בואו נתקדם')
  const [submit, setSubmit] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [nameValidate, setNameValidate] = useState(false);

  useEffect(() => {

    
    // Check if the user is logged in before proceeding
    if (isLoggedIn) return;
  debugger;
    // Function to get the cookie value by name
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };
  
    // Retrieve userIdentification from cookies
    const userIdentification = getCookie('userIdentification');
  
    // If there's no userIdentification, exit the function
    if (!userIdentification) return;
  
    // Define an async function to handle the API request
    const fetchWishlist = async () => {
      try {
        const response = await axios.get(
          `${API_DOMAIN}/api/ProductCategory/GetWishlistByuserIdentification?userIdentification=${userIdentification}&eventId=${eventData.id
          }`
        );
  
        // Check if data exists; if null, exit the function
        if (!response.data) return;
  
        // If successful, set event existence to true
        console.log(response.data);
        setIsCookieEvent(true);
        const confirmation = window.confirm(
          "כבר פתחת אירוע כזה, תרצה להמשיך אותו?"
        );
      
        // If the user confirms, proceed with the deletion
        if (confirmation) {
          navigate(`/all-categories-new-event?wishlistId=${response.data.id}&isBirthdayEvent=${isBirthdayEvent}`);
        }

      } catch (error) {
        console.error("Error retrieving wishlist:", error);
      }
    };
  
    // Call the async function
    fetchWishlist();
  }, []); // Add dependencies as needed
  

  useEffect(()=>{
console.log("&&&&&&", eventData)
  },[eventData])

  useEffect(() => { 
    //debugger;
    setShowError(false);

    if (!selectedYear || !selectedMonth || !selectedDay ) {
        setError(''); // No error if any field is empty
    } else {
        const currentDate = new Date();
        const selectedDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
        // Clear time portion
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        
        if (selectedDate < currentDate) {
            setError('תאריך עבר');
        } else {
            const maxDaysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
            // Check if selected day exceeds the maximum days in the selected month
            if (selectedDay > maxDaysInMonth) {
                setError('תאריך לא קיים');
            } else {
                setError('');
            }
        }
        const dateValidation = validateEventDate(selectedYear, selectedMonth, selectedDay)
     //   if(dateValidation == "Date too far")
    //      setButtonText('בואו נתקדם')
    //    else
      //  setButtonText('בואו נתקדם לשלב הכיפי')

    }
}, [selectedYear, selectedMonth, selectedDay]);
function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

  const handleNameChange = (e) => {
    setEventName(e.target.value);
    if(eventName)
    setNameValidate(false)

    if(submit && !eventName)
     setNameValidate(true)
  };

  // Function to handle year change
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
   // alert(e.target.value);

  };

  // Function to handle month change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  //  alert(e.target.value);

  };

  // Function to handle day change
  const handleDayChange = (e) => {   
     setSelectedDay(e.target.value);


  
  //  alert(e.target.value);

  };
  window.localStorage.setItem('isBirthdayEvent', JSON.stringify(isBirthdayEvent))
  console.log("urlParams", urlParams);
  // setUrlWishListId(urlParams?.wishlistId);
  const [resultInfo, setResultInfo] = useState({
    eventTypeName: params?.eventTypeName ? params.eventTypeName : "",
    eventTypeNameEng: params?.eventTypeNameEng ? params.eventTypeNameEng : "",
    fullname: "",
    birthdate: "",
    fullname2: "",
    birthdate2: "",
    // address: "",
    city: "",
    street: "",
    addressDetails: "",
    postal: "",
    mailbox: "",
    time: "",
    number: "",
    appTypesList: [],
  });
  useEffect(() => {
    // console.log("params.eventTypeDate", params);
    // console.log(resultInfo);
  }, [resultInfo]);
  const [isReadyToSend, setReadyToSend] = useState(false);
  const [step0SelectedDate, setStep0SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step0DayRef = useRef(null);
  const step0MonthRef = useRef(null);
  const step0YearRef = useRef(null);

  const [step1SelectedDate, setStep1SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step1DayRef = useRef(null);
  const step1MonthRef = useRef(null);
  const step1YearRef = useRef(null);
  const [finalInviteUrl, setFinalInviteUrl] = useState(null);
  const [  showError, setShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [isBirthdayMode, setIsBirthdayMode] = useState(eventData?.englishName?.includes('birthday'));
  const [test, isTest] = useState(true);
  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);
  const today = new Date();
  const formattedToday = today.toISOString().slice(0, 10);
  const [error, setError] = useState('');
  const [eventName, setEventName] = useState('');

  
  const refPopupContent = useClickOutside(handleClose);

  useEffect(()=>{

  },[eventName])
  const removeAsterisk = (e) => {
    const label = e.target.closest('label');
    console.log(label)
    label.classList.remove('required-parent')
  }

  const addAsterisk = (e) => {
    const input = e.target;
    const label = e.target.closest('label');
    if (input.value.length > 0) return
    label.classList.add('required-parent')

  }


  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log('isBirthdayMode: ', isBirthdayMode)

  useEffect(() => {
    const userAgent = window.navigator?.userAgent;
    setIsMobile(/Mobi|Android/i.test(userAgent));
  }, []);

  useEffect(() => {
    const scrollButtonClick = () => {
      if (createPopupRef.current) {
        createPopupRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    if (isMobile) {
      scrollButtonClick()
    }


  }, [isMobile])
  const goToCartByWishlist1 = () => {
    alert("222222222222")
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${sortWishlists[0].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };
  useEffect(()=>{
    console.log("LLLLLLLLLLLLLLL", currWishlist)
  },[currWishlist])

  const [isOpenSmsPopup, setIsOpenSmsPopup] = useState(false)

  const toggleAddSmsPopup = (e) => {
    e.preventDefault()
    setIsOpenSmsPopup(!isOpenSmsPopup)
  }

  const copyLinkToClipboard = (e, link) => {
    e.preventDefault()

    const button = e.target.closest(".btn.copy-link");
    button.style.position = "relative";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        const hint = document.createElement("div");
        hint.classList.add("single-books-item__dropdown-table-item");
        hint.innerHTML = `<p class="value">wishlist link copied to clipboard!</p>`;
        hint.style.cssText =
          "position: absolute; top: 125%; right: 50%; transform: translateX(50%); width: auto; height: auto; object-fit: unset; white-space: nowrap; background-color:#fff;";
        button.appendChild(hint);
        setTimeout(() => {
          button.removeChild(hint);
        }, 2000);
      })
      .catch((error) => {
        console.error("Ошибка при копировании в буфер обмена:", error);
      });
  };

  // console.log("step0Info_: ", step0Info);

  const goBack = (e) => {
    e.preventDefault()
    // navigate(-1); no navigation for popup needed, just close popup
    handleClose()
    };

  const goToStep0 = () => {
    console.log("trying to set isBir")
    setIsBirthdayMode(currWishlist?.whishlistObject?.isBirthDay);
    localStorage.setItem('isBirthdayMode', params?.eventTypeNameEng?.includes("birthday") ? "true" : "");
    setCreateEventStep(0);
    setStepNumber(1);
    setProgress(5);
    setForm(true);
    setDecor(true);
    setReadyToSend(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    window.scrollTo(0, 0);
  };
  const handleStep0DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep0SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
    window.scrollTo(0, 0);

  };

  useEffect(() => {
    // date is passed from my events component when editing existing event
    function getDateObject(dateString) {
      const dateObj = new Date(dateString);

      const result = {
        date: dateObj.toISOString().slice(0, 10),
        day: dateObj.getDate(),
        month: dateObj.getMonth() + 1,
        year: dateObj.getFullYear(),
      };

      return result;
    }

    if (params.eventTypeDate) {
      const date = getDateObject(params.eventTypeDate);
      // console.log("eventTypeDate_", date);

      setStep0SelectedDate(date);
    }

    if (params.eventTypeName) {
      setResultInfo((prev) => ({
        ...prev,
        eventTypeName: params.eventTypeName,
      }));
      setStep0Info((prev) => ({ ...prev, fullname: params.eventTypeName }));
    }

    return () => { };
  }, [params.eventTypeDate, params.eventTypeName]);

  const handleStep1DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep1SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
    window.scrollTo(0, 0);

  };

  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value) {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    }
  };
  const submitStep0Form=(e)=>{
    e.preventDefault();

    const form = e.target;
    const fullnameInput = form["fullname"];

    const birthdayInputYear = selectedYear;
     const birthdayInputMonth = selectedMonth;
   const birthdayInputDay = selectedDay;

      const isBirthYear = selectedYear;
      const isBirthMonth = selectedMonth;
      const isBirthDay = selectedDay;
 

    const isFullname =  validateTextInput(fullnameInput, 3); // if birthday event, add inputs for fullname and date otherwise skip them

  if(!selectedDay || !selectedMonth || !selectedDay){
    setShowError(true)
  }
  const dateValidation = validateEventDate(isBirthYear, isBirthMonth, isBirthDay)
//alert(dateValidation)
    let isFormValid = isFullname && isBirthYear && isBirthMonth && isBirthDay && isFullname;
 
    if(isFormValid){

 
    const date = new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`);
    const isoDate = date.toISOString();    
    const sendObj = {
    userId: currUserObject.user.id,
    eventName: eventName,
    name: eventName,
    eventDate: isoDate,
    description: "description",
    productsIdList: [],
  //  eventDate: new Date(utcDate).toISOString(),
    wishListObj: JSON.stringify(selectedEventType)
  };
  
  axios
    .post(`${API_DOMAIN}/api/ProductCategory/CreateWishlist`, sendObj)
    .then((response) => {
      // Handle success     
      // setMyEventsActiveTab(4);
      getWishlistList() ;
  //  alert("נשלח לך תזכורת חודש לפני האירוע");
    handleClose();    
      console.log("Wishlist created successfully:", response.data);
      // You can also update the UI or perform other actions here
    })
    .catch((error) => {
      // Handle error
      console.error("Error creating wishlist:", error);
      // Optionally, display an error message to the user
    });
  debugger;   


   //window.location.reload(); 

    //  navigate("/my-events");
    
  }}
{/*
  const submitStep0Form = (e) => {
    e.preventDefault();

    const form = e.target;
    // console.log("isBirthdayMode params", params);
    //#change
    //setIsBirthdayMode(params?.eventTypeNameEng?.includes("birthday"));
    // console.log("isBirthdayMode ", isBirthdayMode);
    const fullnameInput = form["fullname"];
   // const birthdayInputYear = form["birthday-year"];
   // const birthdayInputMonth = form["birthday-month"];
   // const birthdayInputDay = form["birthday-day"];
    const birthdayInputYear = selectedYear;
     const birthdayInputMonth = selectedMonth;
   const birthdayInputDay = selectedDay;

      const isBirthYear = selectedYear;
      const isBirthMonth = selectedMonth;
      const isBirthDay = selectedDay;
 

    const isFullname =  validateTextInput(fullnameInput, 3); // if birthday event, add inputs for fullname and date otherwise skip them
  //  const isBirthYear = isBirthdayEvent ? validateTextInput(birthdayInputYear, 4) : true;
   // const isBirthMonth = isBirthdayEvent ? validateTextInput(birthdayInputMonth, 1) : true;
  //  const isBirthDay = isBirthdayEvent ? validateTextInput(birthdayInputDay, 1) : true;
  if(!selectedDay || !selectedMonth || !selectedDay){
    setShowError(true)
  }
  const dateValidation = validateEventDate(isBirthYear, isBirthMonth, isBirthDay)
//alert(dateValidation)
    let isFormValid = isFullname && isBirthYear && isBirthMonth && isBirthDay && isFullname;
   // if (!isBirthdayEvent) {
     // isFormValid = isFullname;
    //}
    if(isFormValid){

    if (dateValidation == "Date is valid") {

      setStep0Info({
        fullname:  fullnameInput.value,
        birthDay: selectedDay,
        birthMonth: selectedMonth,
        birthYear: selectedYear,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname:fullnameInput.valu,
        birthdate:
          birthdayInputYear +
          "-" +
          birthdayInputMonth +
          "-" +
          birthdayInputDay
          ,
        fullname2: "",
        birthdate2: "",
        addressDetails: selectedCity + ", " + selectedStreet,
        postal: "",
        mailbox: "",
        time: "",
        number: "",
        appTypesList: [],
        city: selectedCity,
        street: selectedStreet,
      });
    
      const utcDate = Date.UTC(selectedYear, selectedMonth - 1, selectedDay);
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", new Date(utcDate).toISOString());
            const sendObj = {
         userId: currUserObject?.user.id,
        description: "description",
        productsIdList: [],
        eventDate: 
        new Date(utcDate).toISOString(),
        
        // eventDate: "",
        name: fullnameInput.value,
        houseNumber: resultInfo.addressHouse,
        apartmentNumber: resultInfo.addressApartment,
        entranceNumber: resultInfo.addressEntrance,
        mailbox: resultInfo.mailbox,
        postal: resultInfo.postal,
        arrivalTime: resultInfo.time,
        contactPhone: resultInfo.number,
        type: resultInfo?.eventTypeNameEng,
        city: "",
        street: "",
        isBirthDay: isBirthdayMode,
        wishListObj: JSON.stringify(eventData)
      };
      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/CreateWishlist`,
          sendObj
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.result) {
            //  goToStep1();
            //  goToWhilistSelect(response.data.wishlistId);
            setFilterObject({
              choosedCategoriesList: [],
              manufacturersList: [],
              priceRange: null,
              lastChangedType: "",
            })

          if(!isLoggedIn){
            let userIdentification = document.cookie
                .split('; ')
                .find((row) => row.startsWith('userIdentification='))
                ?.split('=')[1];

            // If the cookie doesn't exist, create a new UUID and set the cookie
            if (!userIdentification) {
                userIdentification = generateUUID();
                setCookie('userIdentification', userIdentification, 31);
           }
           const sendObj2={
               wishlistId: response.data.wishlistId,
               userIdentification: userIdentification                      
           }

           axios
           .post(
             `${API_DOMAIN}/api/ProductCategory/SaveUserIdentification`,
             sendObj2
           )

          }

        //    debugger;
            navigate(
              //   `/all-categories?wishlistId=${response.data.wishlistId}`
              // `/all-categories-new-event?wishlistId=${response.data.wishlistId}?isBirthdayEvent=${isBirthdayEvent}`
              `/all-categories-new-event?wishlistId=${response.data.wishlistId}&isBirthdayEvent=${isBirthdayEvent}`
            );
            window.scrollTo(0, 0);
          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  
  else{
    if(dateValidation == "Date has passed")
    {
    }
    if(dateValidation == "Date too far")
    {
      setIsGray(true);
     // toggleCreatePopup(false) 
       setShowFarDatePopup(true)

    }
  }
}
  };*/}

  const goToStep1 = () => {
    //setIsBirthdayMode(params?.eventTypeNameEng?.includes("birthday"));
    setCreateEventStep(1);
    setStepNumber(2);
    setProgress(30);
    setForm(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
    window.scrollTo(0, 0);

  };

  // console.log("Debugg_result_info", resultInfo)
  const submitStep1Form = (e) => {
    // console.log("its me 1")
    const form = e.target
      .closest(".credentials-section__content")
      .querySelector("form");

    const fullnameInput = document.getElementById(
      "form-credentials-section__fullname"
    );
    const telInputFirst = document.getElementById(
      "form-credentials-section__tel-1"
    );
    // const telInputSecond = document.getElementById(
    //   "form-credentials-section__tel-2"
    // );
    const birthdayInputYear = document.getElementById(
      "form-credentials-section__birthday-year"
    );
    const birthdayInputMonth = document.getElementById(
      "form-credentials-section__birthday-month"
    );
    const birthdayInputDay = document.getElementById(
      "form-credentials-section__birthday-day"
    );
    // const addressInput = document.getElementById(
    //   "form-credentials-section__address"
    // );
    const cityInput = document.getElementById("form-credentials-section__city");
    const streetInput = document.getElementById(
      "form-credentials-section__street"
    );

    console.log("Debugg_cityInput, streetInput", cityInput, streetInput)
    const addressHouseInput = document.getElementById(
      "form-credentials-section__house-num"
    );
    const addressApartmentInput = document.getElementById(
      "form-credentials-section__apartment-num"
    );
    const addressEntranceInput = document.getElementById(
      "form-credentials-section__entrance"
    );
    const mailboxInput = document.getElementById(
      "form-credentials-section__mailbox"
    );
    const postalInput = document.getElementById(
      "form-credentials-section__postal-code"
    );
    const timeInput = document.getElementById("form-credentials-section__time");

    const isFullname = validateTextInput(fullnameInput, 3);
    const telInputSecond = validateFullPhoneNumber(telInputSecond);
     const isTelFirst = validateFixedCountInput(telInputFirst, 7);
   const isTelSecond = validatePhoneNumber(telInputSecond);
    const isBirthYear = validateTextInput(birthdayInputYear, 4) ;
    const isBirthMonth =  validateTextInput(birthdayInputMonth, 1) ;
    const isBirthDay = validateTextInput(birthdayInputDay, 1) ;
    validateEventDate(isBirthYear, isBirthMonth, isBirthDay)



    const isCity = validateTextInput(cityInput, 1);
    const isStreet = validateTextInput(streetInput, 1);
    const isAddressHouse = validateTextInput(addressHouseInput, 1);
    const isAddressApartment = validateTextInput(addressApartmentInput, 1);
    const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
     const isMailboxInput = validateFixedCountInput(mailboxInput, 9);
     const isPostalInput = validateTextInput(postalInput, 1);
    const isTime = validateTextInput(timeInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isCity &&
      isStreet &&
      isAddressHouse &&
      isAddressApartment &&
      isAddressEntrance &&
      // isMailboxInput &&
      isPostalInput &&
      isTime;

    if (isFormValid) {
      setStep1Info({
        fullname2: fullnameInput.value,
        // address: addressInput.value,
        city: cityInput.value,
        street: streetInput.value,
        telFirst: telInputFirst.value,
        // telSecond: telInputSecond.value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        mailbox: mailboxInput.value,
        postal: postalInput.value,
        time: timeInput.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        // fullname: resultInfo.fullname,
        // birthdate: resultInfo.birthdate,
        fullname: currWishlist.whishlistObject?.name,
        birthdate: currWishlist.whishlistObject?.eventDate.slice(0, 10),
        fullname2: fullnameInput.value,
        birthdate2:
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value,
        city: cityInput.value,
        street: streetInput.value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        time: form["time"].value,
        // number: telInputSecond.value + telInputFirst.value,
        number: telInputFirst.value,
        appTypesList: [],
      });

      goToStep2();
    } else {
      const elScrollTo = form.querySelector(".input-error");
      let scrollCoord;
      if (elScrollTo.closest(".form-edit-profile__birthday")) {
        scrollCoord = elScrollTo.closest(
          ".form-edit-profile__birthday"
        ).offsetTop;
      } else {
        scrollCoord = elScrollTo.offsetTop;
      }

      // form.parentElement.scrollTo({
      //   top: scrollCoord,
      //   left: 0,
      //   behavior: "smooth",
      // });
    }
    window.scrollTo(0, 0);
  };

  const goToStep2 = () => {
    alert("44444444444")
    //isBirthdayMode = params?.eventTypeNameEng?.includes("birthday");
    setCreateEventStep(2);
    setStepNumber(3);
    setProgress(65);
    setForm(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
    window.scrollTo(0, 0);
  };
  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
  const submitStep2Form = (e) => {
    alert("3333333333")
    console.log("update wishlist hit 2 urlWishlist", urlWishListId);
    const sendObj = {
      userId: currUserObject?.user.id,
      description: resultInfo.fullname,
      productsIdList: [],
      // eventDate: "2023-07-15T09:43:52.718Z",
      eventDate: `${resultInfo.birthdate}T${resultInfo.time}`,
      name: resultInfo.fullname,
      houseNumber: resultInfo.addressHouse,
      apartmentNumber: resultInfo.addressApartment,
      entranceNumber: resultInfo.addressEntrance,
      mailbox: resultInfo.mailbox,
      postal: resultInfo.postal,
      arrivalTime: resultInfo.time,
      contactPhone: resultInfo.number,
      type: resultInfo?.eventTypeNameEng,
      wishlistId: secondStep ? urlWishListId : currWishlist.whishlistObject.id,
      city: resultInfo.city,
      street: resultInfo.street,
    };

    // axios
    //   .post(
    //     `${API_DOMAIN}/api/ProductCategory/CreateWishlist`,
    //     sendObj
    //   )
    //   .then((response) => {
    //     console.log(response.data);
    //     if (response.data.result) {
    //       goToWhilistSelect(response.data.wishlistId);
    //     } else {
    //       //setLoading(false);
    //       alert("Something went wrong");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     //setLoading(false);
    //     alert("Error, please try again");
    //   });
    axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
        sendObj
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          //debugger;
          setCurrWishlist({
            whishlistItemsList: response.data.wishlist.whishlistItemsList,
            whishlistObject: response.data.wishlist.whishlistObject,
            whishlistItemsDetailsList:
              response.data.wishlist.whishlistItemsDetailsList,
          });
          if(resultInfo.birthdate)
          {
            alert(resultInfo.birthdate);
          }

          goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const goToStep3 = () => {
    setCreateEventStep(3);
    setStepNumber(4);
    setProgress(100);
    setForm(false);
    setReadyToSend(false);

    setMainBackgroundgImage({
      main: chooseAppBgImage,
      noPeople: chooseAppBgImageNoPeople,
    });
    setDecor(false);
    window.scrollTo(0, 0);

  };
  const submitStep3Form = (e) => {
    alert("5555555")
    console.log("its me 1")
    e.preventDefault();

    const form = e.target;

    // const isFormValid = Boolean(selectedValues.length);
    const isFormValid = true;
    if (isFormValid) {
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: resultInfo.fullname,
        birthdate: resultInfo.birthdate,
        fullname2: resultInfo.fullname2,
        birthdate2: resultInfo.birthdate2,
        city: resultInfo.city,
        street: resultInfo.street,
        addressDetails: resultInfo.addressDetails,
        postal: resultInfo.postal,
        mailbox: resultInfo.mailbox,
        time: resultInfo.time,
        number: resultInfo.number,
        appTypesList: [selectedValues],
      });
      console.log("update event hit urlwishlistId", urlWishListId);
      const paramsObj = {
        userId: currUserObject?.user.id,
        wishlistId: secondStep
          ? urlWishListId
          : currWishlist.whishlistObject.id,
        isShareEmail: selectedValues.includes("מיילים"),
        description: currWishlist.whishlistObject.description,
      };

      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
          paramsObj
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            console.log("data that we have got", response.data);
            setCurrWishlist({
              whishlistItemsList: response.data.wishlist.whishlistItemsList,
              whishlistObject: response.data.wishlist.whishlistObject,
              whishlistItemsDetailsList:
                response.data.wishlist.whishlistItemsDetailsList,
            });
            setReadyToSend(true);
            window.scrollTo(0, 0);

          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  };

  function goToWhilistSelect(whishlistId) {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          setFinalInviteUrl(
            `${window.location.origin}/?inviteWishlistId=${response.data.whishlistObject.id}`
          );
          // goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  const handleCheckBoxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedValues([...selectedValues, value]);
    } else {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const facebookShare = () => {
    // if (e.target.checked) {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };
  const tiktokShare = () => {
    // if (e.target.checked) {
    window.open(
      `tiktok://share?item_id=${encodeURIComponent(finalInviteUrl)}`,
      "_blank"
    );
    // }
  };
  const whatsappShare = (e) => {
    // if (e.target.checked) {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };
  const instagramShare = (e) => {
    // if (e.target.checked) {
    window.open(
      `https://www.instagram.com/share?url=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };

  const browserShare = (e) => {
    e.preventDefault();
    console.log(navigator.share);
    if (navigator.share) {
      navigator
        .share({
          title: "My wishlist",
          text: "That's invite to my wishlist. You are welcome!",
          url: finalInviteUrl,
        })
        .then(() => {
          console.log("Успешно отправлено");
        })
        .error((error) => {
          console.error(error);
        });
    }
  };



  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);


    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    clearError(document.getElementById("form-credentials-section__city"));
  }
  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };
  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };
  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
    if (newValue) {
      const scrollCoord = streetsLabelRef.current.offsetTop;
      document.querySelector(".credentials-section__form-wrapper").scrollTo({
        top: scrollCoord,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  useEffect(() => {
    // prepare street select
    const ourObject = parseCSVData(citiesText);
    // console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    // console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    // console.log("streetsDistinctList", uniqueStreets);
  }, []);

  // Add the click outside handlers and their refs
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  useEffect(() => {
    if (isReadyToSend) {
      // console.log(resultInfo);
      localStorage.setItem(`event-created`, JSON.stringify(resultInfo));
      // console.log(JSON.parse(localStorage.getItem(`event-created`)));
      navigate("/event-created");
      window.location.reload()
    }
  }, [params, resultInfo, isReadyToSend, navigate]);

  useEffect(() => {
    // not logged in
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    }
    if (secondStep) {
      setUrlWishListId(urlParams?.wishListId);
      getWishlistObject(urlParams?.wishListId)
      // console.log("url params", urlParams);
      goToStep1();
    }
  }, []);

  function getWishlistObject(whishlistId) {
    alert("11111111")
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          console.log("wishilist object", response.data.whishlistObject);
          setIsBirthdayMode(response.data.whishlistObject.isBirthDay);
          // setLoading(false);

       /* */   console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          setFinalInviteUrl(
            `${window.location.origin}/?inviteWishlistId=${response.data.whishlistObject.id}`
          );
          // goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }
  const yearOptions = [];
  const currentYear = getCurrentYear();
  const maxSelectableYear = currentYear + 1; // One year ahead
  const currentMonth = getCurrentMonth() + 1;
  const currentDay = getCurrentDay();
  
  for (let year = currentYear; year <= maxSelectableYear; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  
  const monthNamesHebrew = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר"
  ];
  


  
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  
  function getCurrentMonth() {
    return new Date().getMonth() + 1; // Months are zero-indexed
  }
  
  function getCurrentDay() {
    return new Date().getDate();
  }
  
  const handleCloseExpiredPopup = () => {
    setShowFarDatePopup(false);
 //   updatePopupState(false)
  };

// 1. פונקציה שמחזירה את התאריך המינימלי (היום + חודש אחד)
function getMinDate() {
  const now = new Date();
  now.setMonth(now.getMonth() + 1); // מוסיף חודש אחד
  return now;
}

// 2. כמה ימים יש בחודש (year, month)
function getDaysInMonth(year, month) {
  // month בין 1 ל־12
  return new Date(year, month, 0).getDate();
}

// 3. פונקציה שמייצרת אופציות לימים רק אם התאריך >= חודש מהיום
function generateDayOptions(selectedYear, selectedMonth) {
  // אם לא בחרו שנה/חודש — מחזירים כלום
  if (!selectedYear || !selectedMonth) return [];

  // הפיכת מחרוזות למספרים
  const y = Number(selectedYear);
  const m = Number(selectedMonth);

  // מחשבים את התאריך המינימלי (חודש מהיום)
  const minDate = getMinDate();               // למשל 23/01/2025
  const minYear = minDate.getFullYear();      // 2025
  const minMonth = minDate.getMonth() + 1;    // 1
  const minDay = minDate.getDate();           // 23

  // התאריך שהמשתמש בחר ברמת "חודש-שנה"
  // כדי שנוכל להשוות גם את היום, נשתמש בהמשך
  const totalDays = getDaysInMonth(y, m);

  // לוגיקה פשוטה:
  // 1) אם השנה < minYear => פסול
  // 2) אם השנה > minYear => כל החודש זמין
  // 3) אם השנה == minYear => משווים את החודש
  //     - if (m < minMonth) => פסול
  //     - if (m > minMonth) => כל החודש זמין
  //     - if (m == minMonth) => מתחילים רק מ־minDay ועד לסוף החודש
  
  if (y < minYear) {
    return [];
  } else if (y > minYear) {
    // הכול זמין
    const dayOptions = [];
    for (let day = 1; day <= totalDays; day++) {
      dayOptions.push(
        <option key={day} value={day}>{day}</option>
      );
    }
    return dayOptions;
  } else {
    // y === minYear
    if (m < minMonth) {
      return [];
    } else if (m > minMonth) {
      // כל הימים זמנים
      const dayOptions = [];
      for (let day = 1; day <= totalDays; day++) {
        dayOptions.push(
          <option key={day} value={day}>{day}</option>
        );
      }
      return dayOptions;
    } else {
      // m === minMonth => מתחילים מ־minDay
      const dayOptions = [];
      for (let day = minDay; day <= totalDays; day++) {
        dayOptions.push(
          <option key={day} value={day}>{day}</option>
        );
      }
      return dayOptions;
    }
  }
}

  
  return (
 <>
{showFarDatePopup  &&
<TooFarDate

handleCloseExpiredPopup={handleCloseExpiredPopup}
isLoggedIn = {isLoggedIn}
whishlistId={whishlistId}
userId ={currUserObject?.user.id}
eventId={eventData.id}
eventName = {eventName}
selectedDay={selectedDay}
selectedMonth={selectedMonth}
selectedYear={selectedYear}
eventData={eventData}
//eventDate = {eventDate}
/>}
    <main
      ref={createPopupRef}
      className={`create-event-popup credentials-page ${createEventStep === 0
        ? "create-event"
        : createEventStep === 1
          ? "blessing-form"
          : createEventStep === 2
            ? "blessing-page"
            : createEventStep === 3
              ? "choose-application"
              : ""
        } ${isForm ? "credentials-page_form" : ""} `}
      //  ${isGray ? "popup-overlay1" : ""}
      data-fullscreen
    >
      <section className="credentials-section"

      >

        <div className="credentials-section__content-window"
        >

          <div className="credentials-section__bg-image-ibg">
            <img
            //  src={mainBackgroundgImage.main}
            src="https://server.yyyshopping.com/images/2024-08-09/IRsFQirVP.jpg"
              className="img-people"
              alt="bg"
            />
            <img
              src={mainBackgroundgImage.noPeople}
              className="img-no-people"
              alt="bg"
            />
          </div>
          <div className="credentials-section__content"
            // ref={refPopupContent}
          >
            {createEventStep === 0 && (
              <>
                <button
                  type="button"
                  onClick={goBack}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title credentials-section__title_icon-top">
                  המון מזל טוב
                </h1>
                <h2 className="credentials-section__subtitle">
                  <span className="fat">
                   זוהי תחילתה של חברות מופלאה
                  </span>
                 {/* <br /> {isBirthdayEvent && "מלא/י שם ותאריך לידה"}*/}
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep0Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    {/* {isBirthdayEvent ? ( */}
                    <>
                      <label
                        htmlFor="form-credentials-section__fullname"
                        className="form-credentials-section__input-wrapper input-wrapper required-parent"
                      //  style={{ visibility: isBirthdayEvent ? "visible" : "hidden" }}
                      >
                        {eventName == '' &&
                        <span className="required">*</span>
                        }
                        {/* <span className="input-title">שם אירוע*</span> */}
                        <input
                         style={{marginBottom: '0.8rem'}}
                          autoComplete="disable-autofill"
                          type="text"
                          name="fullname"
                          onChange={handleNameChange}
                          id="form-credentials-section__fullname"
                          className="input red-asterisk"
                          defaultValue={step0Info.fullname}
                          placeholder="איזה שם לתת לאירוע שלך?"
                        //  required={true}
                          //onFocus={removeAsterisk}
                        //  onBlur={addAsterisk}
                        //  disabled={!isBirthdayEvent}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 3);
                            }
                          }}
                        />
                      {nameValidate &&
                        <span className="error-text">חובה לתת שם</span>
                        }
                      </label>
                      <label
  htmlFor="form-credentials-section__event-type"
  className="form-credentials-section__input-wrapper input-wrapper"
>
  <span className="input-title">סוג האירוע</span>
  <select
    id="form-credentials-section__event-type"
    value={selectedEventType?.categoryName || ""} // Ensure correct value is displayed
    onChange={handleEventTypeChange}
    className="input"
    style={{ fontSize: "1.6vh" }}
  >
    <option value="">בחר סוג אירוע</option> {/* Add a default option */}
    {wishlistCategories.map((eventType) => (
      <option
        key={eventType.id}
        value={eventType.categoryName} // Use a unique identifier
        className="main-choose-event__list-item"
      >
        {eventType.categoryName}
      </option>
    ))}
  </select>
</label>


                      <div className="form-edit-profile__birthday birthday-field"
                       // style={{ visibility: isBirthdayEvent ? "visible" : "hidden" }}

                      >
                        <p className="birthday-field__birthday-title form-title"  style={{color: '#61b045'}}>
                         מתי תהיה המסיבה?
                        </p>
                        <div className="birthday-field__birthday-body">
      {/* Year dropdown */}
      <label htmlFor="form-credentials-section__birthday-year" className="birthday-field__input-wrapper input-wrapper">
        <span className="input-title">שנה</span>
        <select
          id="form-credentials-section__birthday-year"
          value={selectedYear}
          onChange={handleYearChange}
          className="input"
          style={{
            fontSize: "1.6vh",
            ...(isMobile ? { paddingTop: "1vh" } : {})
          }}
        >
          <option value=""></option>
          {yearOptions}
        </select>
      </label>

      {/* Month dropdown */}
      <label htmlFor="form-credentials-section__birthday-month" className="birthday-field__input-wrapper input-wrapper">
        <span className="input-title">חודש</span>
        <select
          id="form-credentials-section__birthday-month"
          value={selectedMonth}
          onChange={handleMonthChange}
          className="input"
          style={{fontSize: "1.6vh"}}

        >
          <option value=""></option>
         {monthNamesHebrew.map((month, index) => (
            <option key={index} value={index + 1}>{month}</option>
          ))}
        </select>
      </label>

      {/* Day dropdown */}
      <label htmlFor="form-credentials-section__birthday-day" className="birthday-field__input-wrapper date_scroll input-wrapper">
          <span className="input-title">יום</span>
  <select
            className="input"
 
    id="birthday-day"
    value={selectedDay}
    onChange={(e) => setSelectedDay(e.target.value)}
  >
    <option value=""></option>
    {generateDayOptions(selectedYear, selectedMonth)}
  </select>
</label>

    </div>
    {error && <span style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }}>{error}</span>}

    {showError &&
                  <span style={{color: 'red', fontSize: '1.8vh', textAlign: 'center'}}>בחר תאריך</span>
                 }
 
                      </div>
                    </>
                    {/* ) : ""
                    } */}

            {    isMobile && isLoggedIn  &&  
            <div className="contact-us-form__btn-container btn-container_center">
              <button button type="submit"
                className="popup-btn"
              >
            {buttonText}
             </button>

            </div>}

            {(!isMobile || (isMobile && !isLoggedIn)) && (
  <div className="form-credentials-section__buttons-container">
    <button className="btn btn-pink">
            {buttonText}
    </button>
  </div>
)}

                  </form>
                </div>
              </>
            )}

    
            
          </div>
        </div>
      </section>
      {isLoggedIn &&
        <div className="form-credentials-section__buttons-container mobile">
          <button
            type="submit"
            className="btn footer-mobile"
            style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding: "7px" }}

          >
            <div style={{ flex: 1 }}>
              <NavLink
                to="/"
              >
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.516"
                    height="25.445"
                    viewBox="0 0 25.516 25.445"
                  >
                    <path
                      id="home_1_"
                      data-name="home (1)"
                      d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                      transform="translate(0.771 0.601)"
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1.2"
                    />
                  </svg>
                </div>
                <p className="text1">ראשי</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink to="/contact-us" >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height={26}
                    viewBox="0 0 27 26"
                  >
                    <g
                      id="Сгруппировать_1499"
                      data-name="Сгруппировать 1499"
                      transform="translate(1177 -2115)"
                    >
                      <g
                        id="Эллипс_411"
                        data-name="Эллипс 411"
                        transform="translate(-1177 2115)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_412"
                        data-name="Эллипс 412"
                        transform="translate(-1159 2122)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_413"
                        data-name="Эллипс 413"
                        transform="translate(-1175 2132)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <line
                        id="Линия_524"
                        data-name="Линия 524"
                        x2={10}
                        y2={4}
                        transform="translate(-1168.5 2120.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                      <line
                        id="Линия_525"
                        data-name="Линия 525"
                        y1={6}
                        x2={9}
                        transform="translate(-1166.5 2128.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">צרו קשר</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                to="/my-bank"
              >
                <span className="icon">
                  <svg
                    id="Artboard_1"
                    data-name="Artboard 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.901"
                    height="24.498"
                    viewBox="0 0 23.901 24.498"
                  >
                    <path
                      id="Контур_10114"
                      data-name="Контур 10114"
                      d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                      transform="translate(6.338 -33.519)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10115"
                      data-name="Контур 10115"
                      d="M16,54h.891v.891H16V54Z"
                      transform="translate(6.12 -35.737)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10116"
                      data-name="Контур 10116"
                      d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                      transform="translate(-15.361 -30.746)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10117"
                      data-name="Контур 10117"
                      d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                      transform="translate(-21 -29.083)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2304"
                      data-name="Прямоугольник 2304"
                      width="1.4"
                      height="2.8"
                      transform="translate(7.107 2.8)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2305"
                      data-name="Прямоугольник 2305"
                      width="0.7"
                      height="2.8"
                      transform="translate(9.207)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2306"
                      data-name="Прямоугольник 2306"
                      width="0.7"
                      height="2.8"
                      transform="translate(13.407 8.399)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2307"
                      data-name="Прямоугольник 2307"
                      width="1.4"
                      height="1.4"
                      transform="translate(14.807 11.899)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10118"
                      data-name="Контур 10118"
                      d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                      transform="translate(-18.31 -13.535)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10119"
                      data-name="Контур 10119"
                      d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                      transform="translate(-20.664 -14.17)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10120"
                      data-name="Контур 10120"
                      d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                      transform="translate(-42.772 -20.762)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10121"
                      data-name="Контур 10121"
                      d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                      transform="translate(-37.854 -20.969)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2308"
                      data-name="Прямоугольник 2308"
                      width="0.7"
                      height="0.7"
                      transform="translate(9.207 3.5)"
                      fill="#707070"
                    />
                  </svg>
                </span>
                <p className="text1">הקופה שלי</p>

              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
              //  onClick={() => goToCartByWishlist1()}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.846"
                    height="24.798"
                    viewBox="0 0 19.846 24.798"
                  >
                    <g
                      id="Сгруппировать_74057"
                      data-name="Сгруппировать 74057"
                      transform="translate(0.5 0.5)"
                    >
                      <path
                        id="Artboard_2"
                        data-name="Artboard 2"
                        d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                        transform="translate(-74.992 -18.61)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth={1}
                      />
                      <path
                        id="heart"
                        d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                        transform="translate(-25.26 -15.21)"
                        fill="#ec1b53"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">סל המתנות</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
               // to="/my-events"
               onClick={goBack}
               >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.959"
                    height="22.842"
                    viewBox="0 0 29.959 22.842"
                  >
                    <g
                      id="Сгруппировать_74063"
                      data-name="Сгруппировать 74063"
                      transform="translate(-14 0.5)"
                    >
                      <g
                        id="Artboard_3"
                        data-name="Artboard 3"
                        transform="translate(22.214 0)"
                      >
                        <path
                          id="Контур_38424"
                          data-name="Контур 38424"
                          d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                          transform="translate(-24.307 -20.873)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38425"
                          data-name="Контур 38425"
                          d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                          transform="translate(-20.77 -14.613)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38426"
                          data-name="Контур 38426"
                          d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                          transform="translate(-14.469 -14.389)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_526"
                          data-name="Линия 526"
                          y2="4.793"
                          transform="translate(9.839 13.419)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_527"
                          data-name="Линия 527"
                          y2="8.147"
                          transform="translate(7.443 11.023)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_528"
                          data-name="Линия 528"
                          y2="11.684"
                          transform="translate(5.047 8.445)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_529"
                          data-name="Линия 529"
                          y2="7.055"
                          transform="translate(2.651 14.033)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <circle
                          id="Эллипс_414"
                          data-name="Эллипс 414"
                          cx="1.438"
                          cy="1.438"
                          r="1.438"
                          transform="translate(10.319)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38427"
                          data-name="Контур 38427"
                          d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                          transform="translate(-27.244 -17.922)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38428"
                          data-name="Контур 38428"
                          d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                          transform="translate(-31.988 -10.775)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38429"
                          data-name="Контур 38429"
                          d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                          transform="translate(-20.474 -9.487)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38430"
                          data-name="Контур 38430"
                          d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                          transform="translate(-28.181 -7.104)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                      <g
                        id="Сгруппировать_1710"
                        data-name="Сгруппировать 1710"
                        transform="translate(14 2.322)"
                      >
                        <g
                          id="Контур_10101"
                          data-name="Контур 10101"
                          transform="translate(0 1.369)"
                          fill="#3c9e1c"
                        >
                          <path
                            d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                            stroke="none"
                          />
                          <path
                            d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                            stroke="none"
                            fill="#fff"
                          />
                        </g>
                        <text
                          id="_"
                          data-name="+"
                          transform="translate(6.845 12.059)"
                          fill="#fff"
                          fontSize="11"
                          fontFamily="Assistant-SemiBold, Assistant"
                          fontWeight="600"
                        >
                          <tspan
                            x="2.783"
                            y="0"
                          >
                            +
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </span>
                <p className="text1">אירוע חדש</p>


              </NavLink>
            </div>

          </button>
        </div>}
    </main>
   </>
  );
}

export default CreateFutureEventPopup;
