import React, { useEffect, useRef, useState } from "react";
import {
  mainDecor,
  prevBtnImage,
  orangeBgImage,
  orangeBgImageNoPeople,
  mainBgImage,
  mainBgImageNoPeople,
  logoImg,
  imgAfterRegister,
  loginWithIcon,
} from "../../Assets/images";

import {
  validateName,
  validateTextInput,
  validateEmailInput,
  validateFixedCountInput,
  inputMaxCount,
  validatePhoneNumber,
  clearError,
  isIsraelNumber,
  validateFullPhoneNumber,
} from "../../Libs/forms";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { API_DOMAIN } from "../../Libs/config";
// import { CodeNotRightPopup } from "../../Popups/code-not-right-popup";
import CodeNotRightPopup from "../../Popups/code-not-right-popup";
import axios from "axios";
import { citiesText } from "../../Libs/citiesText";
import { useClickOutsideMulti } from "../../hooks/useClickOutsideMulti";
let streetsArr = null,
  citiesArr = null;

function RegisterReferalWishlist({
  isLoggedInHandler,
  currUserObject,
  setCurrUserObject,
  updateLoginOrRegister,
  updateStepNumber,
  updateColorMode,
  updateTopNavButtons,
  isTopNavButtons,
  updateMainBgImage,
  updateVerifyNumberPopup,
  updatePopupState,
  updateLoginWith,
  registerStep,
  updateRegisterStep,
  updateIsForm,
  updateCodeNotRightPopup,
  progress,
  updateProgress,
  updateGreenMobile,
  updateLoginStep,
  updateCodeNotRightMessage,
  codeNotRightMessage,
  isRedirectPresent,
  setNotLoggedIn,
  updateDidRegistered,
  language,
  languageToggle,
  isCodeNotRightPopup,
  resetOtpInputs
}) {
  const location = useLocation();
  const hasNewWishlistPath = location.pathname.includes('credentials-new-wishlist');
  const navigate = useNavigate();
  const params = location?.state ? location.state : {};
  const currWishlistId = params?.currWishlistId;
  const [isLoading, setLoading] = useState(false);
  const [rightSmsCode, setRightSmsCode] = useState("");
  const [citiesObject, setCitiesObject] = useState([]);
  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [buildingNumber, setBuildingNumber] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    date: ``,
    day: "",
    month: "",
    year: "",
  });
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const [registerFormInfo, setRegisterFormInfo] = useState({
    fullname: "",
    telSecond: "",
    telFirst: "",
    email: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
    address: "",
    postal: "",
    mailbox: "",
    isRemember: false,
  });

  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);

  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();

  // Add the click outside handlers and their refs
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  function citySearchHandler(e) {
    const value = e.target.value;
    if (value.length === 0) {
      // setCitiesObject([])
      setCitiesObject(citiesArr);
      return;
    }
    setCitiesObject(() =>
      citiesArr.filter((city) => city.city_name.includes(value))
    );
  }

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);
    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    clearError(document.getElementById("form-credentials-section__city"));
  }

  function streetSearchHandler(e) {
    console.log("streetSearchHandler");
    const value = e.target.value;
    setSelectedStreet(value);
    if (value.length === 0) {
      setStreets([]);
      return;
    }
    const filteredStreets = streetsArr.filter((street) =>
      street.street_name.includes(value)
    );
    console.log("filteredStreets: ", filteredStreets, "value:", value);
    setStreets(filteredStreets);
  }

  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  useEffect(() => {
    const ourObject = parseCSVData(citiesText);
    console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    console.log("streetsDistinctList", uniqueStreets);
  }, []);

  const handleDateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    setSelectedDate({
      date: result.date,
      day: result.day,
      month: result.month,
      year: result.year,
    });
  };
  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value) {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    }
  };

  const submitRegisterForm = (e) => {
    e.preventDefault();

    const form = e.target;

    // const registerDetailsInfo = {
    //   username: "",
    //   email: "",
    //   password: "",
    //   firstName: "",
    //   lastName: "",
    //   phoneNumber: "",
    //   roleId: "",
    //   companyName: "",
    //   bankAccountId: "",
    //   birthdate: "",
    //   address: "",
    //   mailbox: "",
    //   postal: "",
    //   // isRemember: false,
    // };

    const fullnameInput = document.getElementById(
      "form-credentials-section__fullname"
    );
    const telInputFirst = document.getElementById(
      "form-credentials-section__tel-1"
    );
    // const telInputSecond = document.getElementById(
    //   "form-credentials-section__tel-2"
    // );
    const emailInput = document.getElementById(
      "form-credentials-section__email"
    );
    const birthdayInputYear = document.getElementById(
      "form-credentials-section__birthday-year"
    );
    const birthdayInputMonth = document.getElementById(
      "form-credentials-section__birthday-month"
    );
    const birthdayInputDay = document.getElementById(
      "form-credentials-section__birthday-day"
    );
    const cityInput = document.getElementById("form-credentials-section__city");
    const streetInput = document.getElementById(
      "form-credentials-section__street"
    );
    const buildingInput = document.getElementById(
      "form-credentials-section__building"
    );
    const mailboxInput = document.getElementById(
      "form-credentials-section__mailbox"
    );
    const postalInput = document.getElementById(
      "form-credentials-section__postal-code"
    );

    const isFullname = validateName(fullnameInput, 2, 2);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelFirst = validateFixedCountInput(telInputFirst, 10) && isIsraelNumber(telInputFirst);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isEmail = validateEmailInput(emailInput);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);
    console.log(cityInput);
    const isCityInput = validateTextInput(cityInput, 1);
    const isStreetInput = validateTextInput(streetInput, 1);
    const isBuildingInput = validateTextInput(buildingInput, 1);
    // const isMailboxInput = validateFixedCountInput(mailboxInput, 9);
    const isPostalInput = validateTextInput(postalInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isEmail &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isCityInput &&
      isStreetInput &&
      isBuildingInput &&
      // isMailboxInput &&
      isPostalInput;

    if (isFormValid) {
      setRegisterFormInfo({
        fullname: fullnameInput.value,
        // telSecond: telInputSecond.value,
        telFirst: telInputFirst.value,
        email: emailInput.value,
        birthDay: birthdayInputDay.value,
        birthMonth: birthdayInputMonth.value,
        birthYear: birthdayInputYear.value,
        city: cityInput.value,
        street: streetInput.value,
        building: buildingInput.value,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        isRemember: form["isRemember"].checked,
      });

      const registerDetailsInfo = {
        username: emailInput.value,
        email: emailInput.value,
        password: "123123",
        firstName: fullnameInput.value.split(" ")[0],
        lastName: fullnameInput.value.split(" ")[1],
        phoneNumber: telInputFirst.value,
        roleId: "",
        companyName: "",
        bankAccountId: "",
        postal: form["postal-code"].value,
        mailBox: form["mailbox"].value,
        birthdate:
          birthdayInputDay.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputYear.value,
        // address: buildingNumber + " " + selectedStreet + " " + selectedCity,
        entranceNumber: "",
        apartmentNumber: "",
        houseNumber: buildingInput.value,
        city: cityInput.value,
        street: streetInput.value,
        // isRemember: false,
      };

      console.log(registerDetailsInfo);

      setLoading(true);
      axios
        .post(
          `${API_DOMAIN}/api/Authenticate/register`,
          registerDetailsInfo
        )
        .then((response) => {
          console.log(response.data);
          // goToSubmitSmsCodeStep();
          axios
            .get(
              `${API_DOMAIN}/api/Authenticate/GetOTP?phoneNumber=${telInputFirst.value}`
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.result) {
                setLoading(false);
                //setRightSmsCode(response.data.otpCode);

                //isLoggedInHandler(true);
                goToSubmitSmsCodeStep();
                window.scrollTo(0, 0);

              } else {
                setLoading(false);
                alert("Something went wrong");
              }
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
              alert("Error, please try again");
            });
        })
        .catch((error) => {
          setLoading(false);
          // console.error(error.response.data.message);
          console.log(error);
          if (
            error.response &&
            error.response.data.message === "User already exists!"
          ) {
            alert("The User already exists. Please login");
          }
        });
    }
  };

  const resendOTPHandler = (e) => {
    e.preventDefault()

    axios
      .get(
        `${API_DOMAIN}/api/Authenticate/GetOTP?phoneNumber=${registerFormInfo.telFirst}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          setLoading(false);
          //setRightSmsCode(response.data.otpCode);
          //isLoggedInHandler(true);
          // goToSubmitSmsCodeStep();
        } else {
          setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("Error, please try again");
      });

  }

  const setChooseLoginOrRegister = () => {
    updateLoginOrRegister({
      choose: true,
      register: false,
      login: false,
    });
    updateMainBgImage(orangeBgImage, orangeBgImageNoPeople);
    updateColorMode(true);
    updateStepNumber(2);
    updateTopNavButtons(false);
    updateGreenMobile(true);
  };
  const setLogin = () => {
    updateLoginOrRegister({
      choose: false,
      register: false,
      login: true,
    });
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateLoginStep({
      enterPhone: true,
      loginWithSms: false,
    });
    updateLoginWith({
      loginWithPhone: true,
      loginWithSms: false,
    });
    updateProgress(5);
    updateGreenMobile(false);
  };

  function loginOTP(otpCode, phoneNumber) {
    const data = {
      phoneNumber: phoneNumber,
      OTPCode: otpCode,
    };
    axios
      .post(
        `${API_DOMAIN}/api/Authenticate/LoginWithOTP`,
        data
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("user data", response.data);
          isLoggedInHandler(true);
          setCurrUserObject(response.data);
          localStorage.setItem("currUserObject", JSON.stringify(response.data));
          console.log('hasNewWishlistPath:', hasNewWishlistPath)
          if (updateDidRegistered) {
            updateDidRegistered(true)
          }
          if (hasNewWishlistPath) {
            if (isRedirectPresent) {
              console.log('setNotLoggedIn')
              setNotLoggedIn(false)
            } else {
              navigate(`/create-event-second-step/${currWishlistId}`);
              window.location.reload();
            }
          } else {
            if (isRedirectPresent) {
              console.log('setNotLoggedIn')
              setNotLoggedIn(false)
              localStorage.setItem('presentOrMoney', location.state.presentOrMoney)
              const wishlistId = JSON.parse(localStorage.getItem('inviteWishlistId'))
              navigate(`/choose-present-or-send-money/${wishlistId}/${location.state.presentOrMoney == 'present' ? '' : 'send-money/how-much'}`);
            } else {
              navigate("/");
              window.location.reload();
            }
          }
          window.scrollTo(0, 0);

        } else {
          setLoading(false);
          updateCodeNotRightPopup(true);
          updatePopupState(true);
          updateCodeNotRightMessage(response.data.message)
          resetOtpInputs()
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("Error, please try again");
      });
  }

  function submitSmsCodeForm(e) {
    e.preventDefault();

    const form = e.target;
    let isCodeValid = false,
      isCodeInputsValid = [false, false, false, false, false];
    let userCode = "";
    for (let i = 0; i < form["code"].length; i++) {
      isCodeInputsValid[i] = validateTextInput(form["code"][i], 1);
      userCode += form["code"][i].value;
    }
    isCodeValid = isCodeInputsValid.every((el) => el === true);
    if (isCodeValid) {
      userCode = userCode.split("").reverse().join("");
      loginOTP(userCode, registerFormInfo.telFirst);
    }
    window.scrollTo(0, 0);

  }

  function goToSubmitSmsCodeStep() {
    updateRegisterStep({
      userDetails: false,
      verifyPhone: true,
      acceptTerms: false,
      afterRegister: false,
    });
    updateVerifyNumberPopup(true);
    updateColorMode(false);
    updateTopNavButtons(true);
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updatePopupState(true);
    updateIsForm(true);
    updateLoginWith({
      loginWithPhone: false,
      loginWithSms: true,
    });
    updateProgress(10);
    updateGreenMobile(false);
    window.scrollTo(0, 0);

  }

  function goToSubmitTermsForm() {
    updateRegisterStep({
      userDetails: false,
      verifyPhone: false,
      acceptTerms: true,
      afterRegister: false,
    });
    updateLoginWith({
      loginWithPhone: false,
      loginWithSms: false,
    });
    updateTopNavButtons(false);
    updateColorMode(true);
    updateIsForm(true);
    updateMainBgImage(orangeBgImage, orangeBgImageNoPeople);
    updateProgress(25);
    updateGreenMobile(true);
    window.scrollTo(0, 0);

  }

  function submitTermsForm(e) {
    e.preventDefault();
    console.log("submitTermsForm", e.target);

    const form = e.target;
    const isTermsAccepted = form["isAcceptedTerms"];
    console.log("isTermsAccepted", isTermsAccepted);
    if (!isTermsAccepted.checked) {
      if (!isTermsAccepted.nextElementSibling.classList.contains("input-error"))
        isTermsAccepted.nextElementSibling.classList.add("input-error");
    } else {
      goToAfterRegisterStep();
    }
  }

  function goToAfterRegisterStep() {
    updateRegisterStep({
      userDetails: false,
      verifyPhone: false,
      acceptTerms: false,
      afterRegister: true,
    });
    updateTopNavButtons(false);
    updateColorMode(false);
    updateIsForm(false);
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateProgress(50);
    updateGreenMobile(false);
    window.scrollTo(0, 0);

  }

  const goToNextCodeInput = (e) => {
    const target = e.target;
    const value = target.value;
    target.blur();
    if (target.parentElement.previousElementSibling) {
      const nextEl = target.parentElement.previousElementSibling.children[0];
      nextEl.focus();
      if (value.length > 1) {
        nextEl.value = value.slice(1);
        nextEl.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };


  const goToPrevCodeInput = (e) => {
    const target = e.target;
    target.blur();
    if (target.parentElement.nextElementSibling) {
      target.parentElement.nextElementSibling.children[0].focus();
    }
  };


  const handleCodeInput = (e) => {
    if (e.target.closest("label").classList.contains("input-error")) {
      validateTextInput(e.target, 1);
    }
    if (e.target.value) {
      goToNextCodeInput(e);
    } else {
      goToPrevCodeInput(e);
    }
    inputMaxCount(e.target, 1);
  };


  const goBackIfCodeInputEmpty = (e) => {
    if (
      e.target.value === "" &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      goToPrevCodeInput(e);
    }
  };

  const onOtpInputFocus = (e) => {
    //saves from bugs, on cell click selects value 
    e.target.closest('input').select()
  }

  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };

  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };

  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
  };

  return (
    <>

      {isCodeNotRightPopup && (
        <CodeNotRightPopup
          codeNotRightMessage={codeNotRightMessage}
          updateCodeNotRightPopup={updateCodeNotRightPopup}
          updatePopupState={updatePopupState}
          resendOTPHandler={resendOTPHandler}
        />
      )}


      {isTopNavButtons ? (
        <div className="credentials-section__top-nav-buttons">
          <button className="credentials-section__top-nav-btn reg active">
          חדשים כאן , רוצה להרשם?          </button>
          <button
            className="credentials-section__top-nav-btn login"
            onClick={setLogin}
          >
           כבר נרשמתי בעבר
          </button>
        </div>
      ) : (
        ""
      )}

      {registerStep.userDetails ? (
        <>
          <button
            onClick={setChooseLoginOrRegister}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title credentials-section__title_icon-top">
            <div className="mobile-icon">
              <img src={loginWithIcon} alt="icon" />
            </div>
            הרשמה לאתר
          </h1>
          <h2 className="credentials-section__text">
            חדשים כאן, רוצים להרשם?
          </h2>

          <div
            className={`credentials-section__form-wrapper ${isLoading ? "loading" : ""
              }`}
          >
            <form
              action="#"
              className="credentials-section__form form-credentials-section"
              onSubmit={submitRegisterForm}
            >
              <label
                htmlFor="form-credentials-section__fullname"
                className="form-credentials-section__input-wrapper input-wrapper"
              >
                <span className="input-title">שם מלא*</span>
                <input
                  autoComplete="off"
                  type="text"
                  name="fullname"
                  id="form-credentials-section__fullname"
                  className="input"
                  defaultValue={registerFormInfo.fullname}
                  onInput={(e) => {
                    if (
                      e.target
                        .closest("label")
                        .classList.contains("input-error")
                    ) {
                      validateName(e.target, 2, 2);
                    }
                  }}
                />
                <span className="error-text">לפחות 2 מילים ו2 תוים</span>
              </label>
              <label
                htmlFor="form-credentials-section__tel-1"
                className="input-wrapper"
              >
                <span className="input-title">טלפון לתיאומים</span>
                <input
                  autoComplete="off"
                  type="text"
                  name="tel-1"
                  id="form-credentials-section__tel-1"
                  className="input"
                  defaultValue={registerFormInfo.telFirst}
                  onInput={(e) => {
                    inputMaxCount(e.target, 10, "phone");
                    if (
                      e.target
                        .closest("label")
                        .classList.contains("input-error")
                    ) {
                      // validateFixedCountInput(e.target, 10, true);
                      validateFullPhoneNumber(e.target);
                    }
                  }}
                />
                <span className="error-text">
                  מספר פלאפון לא תקין
                </span>
              </label>
              {/* <div className="tel-wrapper">
                <label
                  htmlFor="form-credentials-section__tel-2"
                  className="input-wrapper"
                >
                  <input
                    autoComplete="off"
                    type="number"
                    name="tel-2"
                    id="form-credentials-section__tel-2"
                    className="input"
                    defaultValue={registerFormInfo.telSecond}
                    onInput={(e) => {
                      inputMaxCount(e.target, 3);

                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validatePhoneNumber(e.target);
                      }
                    }}
                  />
                  <span className="error-text">*05</span>
                </label>
              </div> */}
              <label
                htmlFor="form-credentials-section__email"
                className="form-credentials-section__input-wrapper input-wrapper"
              >
                <span className="input-title">מייל</span>
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  id="form-credentials-section__email"
                  className="input"
                  defaultValue={registerFormInfo.email}
                  onInput={(e) => {
                    if (
                      e.target
                        .closest("label")
                        .classList.contains("input-error")
                    ) {
                      validateEmailInput(e.target);
                    }
                  }}
                />
                <span className="error-text">אימייל לא חוקי</span>
              </label>
              <div className="form-edit-profile__birthday birthday-field">
                <p className="birthday-field__birthday-title form-title">
                תאריך לידה של הנרשם לאתר

                </p>
                <div className="birthday-field__birthday-body">
                  <input
                    type="date"
                    className="birthday-field__hidden-main-field"
                    value={selectedDate.date}
                    onChange={(e) => {
                      handleDateChange(
                        e.target,
                        dayRef.current,
                        monthRef.current,
                        yearRef.current
                      );
                    }}
                  />
                  <label
                    htmlFor="form-credentials-section__birthday-year"
                    className="birthday-field__input-wrapper input-wrapper"
                  >
                    <span className="input-title">שנה</span>
                    <input
                      autoComplete="off"
                      type="number"
                      name="birthday-year"
                      id="form-credentials-section__birthday-year"
                      className="input"
                      ref={yearRef}
                      value={selectedDate.year}
                      onChange={(event) => { }}
                    />
                    <span
                      className="error-text"
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        width: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      חובה לבחור תאריך
                    </span>
                  </label>
                  <label
                    htmlFor="form-credentials-section__birthday-month"
                    className="birthday-field__input-wrapper input-wrapper"
                  >
                    <span className="input-title">חודש</span>
                    <input
                      autoComplete="off"
                      type="number"
                      name="birthday-month"
                      id="form-credentials-section__birthday-month"
                      className="input"
                      ref={monthRef}
                      value={selectedDate.month}
                      onChange={(event) => { }}
                    />
                  </label>
                  <label
                    htmlFor="form-credentials-section__birthday-day"
                    className="birthday-field__input-wrapper input-wrapper"
                  >
                    <span className="input-title">יום</span>
                    <input
                      autoComplete="off"
                      type="number"
                      name="birthday-day"
                      id="form-credentials-section__birthday-day"
                      className="input"
                      ref={dayRef}
                      value={selectedDate.day}
                      onChange={(event) => { }}
                    />
                  </label>
                </div>
              </div>
              <p className="form-title">כתובת</p>
              <div
                ref={citiesLabelRef}
                // htmlFor="form-credentials-section__address"
                className="form-credentials-section__input-wrapper input-wrapper label"
              >
                {/* <span className="input-title">כתובת מגורים</span> */}
                <span className="input-title">עיר</span>

                {/* <input
                  autoComplete="off"
                  type="text"
                  name="address"
                  id="form-credentials-section__address"
                  className="input"
                  // defaultValue={registerFormInfo.address}
                  value={selectedCity}
                  onChange={(e) => {
                    citySearchHandler(e)
                    setSelectedCity(e.target.value)
                  }}
                  onInput={(e) => {
                    if (
                      e.target
                        .closest("label")
                        .classList.contains("input-error")
                    ) {
                      validateTextInput(e.target, 1);
                    }
                  }}
                />
                {citiesObject?.length > 0 && (
                  <ul
                    className="dropdown-menu inner show"
                    role="presentation"
                  >
                    {citiesObject.map((city, index) => (
                      <li key={index}>
                        <button
                          type="button"
                          role="option"
                          className="dropdown-item"
                          id={`bs-select-1-${index}`}
                          tabIndex={0}
                          onClick={() => citySelectHandler(city)}
                        >
                          <span className="text">
                            {city["city_name"]}
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )} */}
                <button
                  type="button"
                  onClick={handleCitiesOpen}
                  className="city-street-select-placeholder"
                ></button>
                <input
                  autoComplete="off"
                  type="text"
                  name="address"
                  id="form-credentials-section__city"
                  className="input input-address"
                  // defaultValue={registerFormInfo.address}
                  value={selectedCity}
                />
                {formCitiesOpen && (
                  <>
                    <ul
                      className="dropdown-menu inner show"
                      role="presentation"
                    >
                      <div
                        className="bs-searchbox"
                        style={{ padding: "0.3125rem 0.5rem" }}
                      >
                        <input
                          type="search"
                          className="input"
                          autoComplete="off"
                          aria-label="Search"
                          aria-controls="bs-select-1"
                          aria-autocomplete="list"
                          aria-activedescendant="bs-select-1-66"
                          onInput={(e) => {
                            const input = e.target;
                            const searchList =
                              citiesLabelRef.current.querySelectorAll(
                                ".dropdown-menu.inner li .dropdown-item .text"
                              );
                            handleSearch(input, searchList);
                          }}
                        />
                      </div>
                      {citiesArr.map((city, index) => (
                        <li key={index}>
                          <button
                            type="button"
                            role="option"
                            className="dropdown-item"
                            id={`bs-select-1-${index}`}
                            tabIndex={0}
                            onClick={() => citySelectHandler(city)}
                          >
                            <span className="text">{city["city_name"]}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                <span className="error-text">חובה לבחור עיר</span>
              </div>
              {/* <p className="form-title">כתובת</p> */}
              <div className="street-wrapper">
                <div
                  ref={streetsLabelRef}
                  // htmlFor="form-credentials-section__address"
                  className="form-credentials-section__input-wrapper input-wrapper street label"
                >
                  <span className="input-title">רחוב</span>
                  {/* <input
                    autoComplete="off"
                    type="text"
                    name="address"
                    id="form-credentials-section__address"
                    className="input"
                    value={selectedStreet}
                    // defaultValue={registerFormInfo.address}
                    onChange={(e) => {
                      streetSearchHandler(e);
                    }}
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateTextInput(e.target, 1);
                      }
                    }}
                  />
                  {streets?.length > 0 && (
                    <ul
                      className="dropdown-menu inner show"
                      role="presentation"
                    >
                      {streets.map((street, index) => (
                        <li key={index}>
                          <button
                            type="button"
                            role="option"
                            className="dropdown-item"
                            id={`bs-select-1-${index}`}
                            tabIndex={0}
                            onClick={() => streetSelectHandler(street)}
                          >
                            <span className="text">
                              {street["street_name"]}
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  )} */}

                  <button
                    type="button"
                    onClick={handleStreetsOpen}
                    className="city-street-select-placeholder"
                  ></button>
                  <input
                    autoComplete="off"
                    type="text"
                    name="address"
                    id="form-credentials-section__street"
                    className="input"
                    value={selectedStreet}
                  />
                  {selectedCity && formStreetsOpen && (
                    <ul
                      className="dropdown-menu inner show"
                      role="presentation"
                    >
                      <div
                        className="bs-searchbox"
                        style={{ padding: "0.3125rem 0.5rem" }}
                      >
                        <input
                          type="search"
                          className="input"
                          autoComplete="off"
                          aria-label="Search"
                          aria-controls="bs-select-2"
                          aria-autocomplete="list"
                          onInput={(e) => {
                            const input = e.target;
                            const searchList =
                              streetsLabelRef.current.querySelectorAll(
                                ".dropdown-menu.inner li .dropdown-item .text"
                              );
                            handleSearch(input, searchList);
                          }}
                        />
                      </div>
                      {streets.map((street, index) => (
                        <li key={index}>
                          <button
                            type="button"
                            role="option"
                            className="dropdown-item"
                            id={`bs-select-1-${index}`}
                            tabIndex={0}
                            onClick={() => streetSelectHandler(street)}
                          >
                            <span className="text">
                              {/* {street["street_name"]} */}
                              {street}
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                  <span className="error-text">חובה לכתוב רחוב</span>
                </div>

                <label
                  htmlFor="form-credentials-section__building"
                  className="form-credentials-section__input-wrapper input-wrapper building-number"
                >
                  <span className="input-title">מס בית</span>

                  <input
                    autoComplete="off"
                    type="text"
                    name="building-number"
                    id="form-credentials-section__building"
                    className="input"
                    value={buildingNumber}
                    // defaultValue={registerFormInfo.address}
                    onChange={(e) => {
                      setBuildingNumber(e.target.value);
                      // streetSearchHandler(e)
                    }}
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateTextInput(e.target, 1);
                      }
                    }}
                  />
                  <span className="error-text">לפחות תו אחד</span>
                </label>
              </div>
              <div className="mail-wrapper">
                <label
                  htmlFor="form-credentials-section__mailbox"
                  className="input-wrapper"
                >
                  <span className="input-title">ת.ד</span>
                  <input
                    autoComplete="off"
                    type="number"
                    name="mailbox"
                    id="form-credentials-section__mailbox"
                    className="input"
                    defaultValue={registerFormInfo.mailbox}
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        // validateFixedCountInput(e.target, 9);
                      }
                      // inputMaxCount(e.target, 9);
                    }}
                  />
                  <span className="error-text">9 מספרים</span>
                </label>
                <label
                  htmlFor="form-credentials-section__postal-code"
                  className="input-wrapper"
                >
                  <span className="input-title">מיקוד</span>
                  <input
                    autoComplete="off"
                    type="number"
                    name="postal-code"
                    id="form-credentials-section__postal-code"
                    className="input"
                    defaultValue={registerFormInfo.postal}
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateTextInput(e.target, 1);
                      }
                    }}
                  />
                  <span className="error-text">לפחות תו אחד</span>
                </label>
              </div>
              <div className="form-credentials-section__check">
                <div className="checkbox">
                  <input
                    id="c_1"
                    className="checkbox__input"
                    type="checkbox"
                    value="1"
                    name="isRemember"
                    defaultChecked={registerFormInfo.isRemember}
                  />
                  <label htmlFor="c_1" className="checkbox__label">
                    <span className="checkbox__text">זכור אותי</span>
                  </label>
                </div>
              </div>
              <div className="form-credentials-section__buttons-container">
                <button type="submit" className="btn">
                  {languageToggle ? languageToggle.registrationBtnsLanguage[language].btn1 : "שלחו לי קוד"}
                </button>
              </div>
            </form>
          </div>

          <div className="credentials-section__decor">
            <img src={mainDecor} alt="decor" />
          </div>
        </>
      ) : (
        ""
      )}
      {registerStep.verifyPhone ? (
        <>
          <button
            onClick={() => {
              updateRegisterStep({
                userDetails: true,
                verifyPhone: false,
                acceptTerms: false,
                afterRegister: false,
              });
              updateLoginWith({
                loginWithPhone: false,
                loginWithSms: false,
              });
              updateProgress(5);
            }}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title credentials-section__title_icon-top">
            <div className="mobile-icon">
              <img src="../img/credentials/login-with.svg" alt="icon" />
            </div>
            הזמנה
          </h1>
          <h2 className="credentials-section__text"> מלא/י את הפרטי האירוע</h2>

          <div className="credentials-section__form-wrapper">
            <form
              onSubmit={submitSmsCodeForm}
              className="credentials-section__form form-credentials-section"
            >
              <div className="form-credentials-section__code-wrapper">
                <div className="form-credentials-section__code">
                  <label
                    htmlFor="code-input-1"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-1"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-2"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-2"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-3"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-3"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-4"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-4"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-5"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-5"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="form-credentials-section__buttons-container">
                <button type="submit" className="btn">
                  {languageToggle ? languageToggle.registrationBtnsLanguage[language].btn2 : "כניסה"}


                </button>
              </div>
     
              <p className="credentials-section__text text-link" style={{marginTop: '2em'}}>
                לא קיבלתי קוד אימות <a href="#" onClick={resendOTPHandler}>שלחו לי שוב</a>
              </p>


              {/* <a href="#" className="form-credentials-section__red-link">
                אם הקוד שגוי
              </a> */}
            </form>
          </div>
        </>
      ) : (
        ""
      )}
      {registerStep.acceptTerms ? (
        <>
          <button
            type="button"
            onClick={goToSubmitSmsCodeStep}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title">תקנון</h1>

          <div className="credentials-section__form-wrapper">
            <form
              action="#"
              onSubmit={submitTermsForm}
              className="credentials-section__form form-credentials-section"
            >
              <div className="credentials-section__text-scroll">
                <h1 className="terms-title"> תקנון ותנאי שימוש לאתר “YYY”</h1>
                <br />
                <br />
                YYYSHOPPING מברכים את בחירתכם לגלוש באתר האינטרנט המופעל בכתובת
                WWW.YYYSHOPPING.CO.IL ("האתר"). האתר המשמש כפלטפורמה מקוונת לבתי
                עסק המוכרים מוצרי אופנה / מוצרי חשמל / צעצועים ועוד. ("בתי
                העסק") ומאפשר לבתי העסק לפרסם את מוצריהם לגולשים באתר
                ("הלקוחות"). באתר ניתן לרכוש את המוצרים המוצעים לרכישה על ידי
                בתי העסק ("המוצרים"), וכן להירשם בתור בית עסק ולפרסם את המוצרים
                למכירה. 1. <strong>כללי:</strong>
                <br />
                <br />
                1.1. הגלישה באתר כפופה לתנאים המפורטים בתקנון ותנאי שימוש אלו (״
                <strong>התקנון</strong>״). אנא קרא את התקנון בקפידה, שכן הגלישה
                באתר וביצוע פעולות בו מעידים על הסכמתך לתנאים הכלולים בתקנון.
                כמו כן, האתר שומר את זכותו לשנות את תנאי התקנון, מעת לעת, על פי
                שיקול דעתו הבלעדי וללא הודעה מוקדמת. מועד החלת השינוי כאמור יהיה
                מרגע פרסומו באתר.
                <br />
                <br />
                1.2. האמור בתקנון זה מתייחס באופן שווה לבני שני המינים והשימוש
                בלשון זכר או נקבה הוא מטעמי נוחות בלבד.
                <br />
                <br />
                1.3. תנאי התקנון חלים על שימוש באתר ובתכנים הכלולים בו באמצעות
                כל מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולרי, מחשבי טאבלט
                וכיו"ב). כמו כן הם חלים על השימוש באתר, בין באמצעות רשת האינטרנט
                ובין באמצעות כל רשת או אמצעי תקשורת אחרים.
                <br />
                <br />
                1.4. הגלישה באתר מותרת בכל גיל. הרכישה באתר מיועדת לבעלי כשירות
                משפטית מגיל 18 בלבד. רכישה של קטין מתחת לגיל 18 מחייבת אישור
                הורה או אפוטרופוס.
                <br />
                <br />
                1.5. במקרה שייקבע כי הוראה בתקנון זה אינה ניתנת לאכיפה או שהינה
                חסרת תוקף מטעם כלשהו, לא יהא בכך כדי להשפיע או לפגוע בחוקיותן,
                תקפותן ואכיפתן של שאר הוראות התקנון.
                <br />
                <br />
                2. <strong>הגלישה באתר:</strong>
                <br />
                <br />
                2.1. הגלישה באתר והעיון בו אינם דורשים הרשמה, והוא פתוח לכל
                גולש.
                <br />
                <br />
                2.2. בעת השארת פרטים ו/או רכישה באתר יתבקש הגולש למסור פרטים
                אישיים כגון: שם פרטי, שם משפחה, טלפון וכן כתובת דואר אלקטרוני
                פעילה (לשיקול דעתו של האתר). מסירת פרטים חלקיים או שגויים עלולים
                למנוע את האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך.
                במקרה של שינוי פרטים יש לעדכנם באתר.
                <br />
                <br />
                2.3. מובהר כי אין חובה על-פי חוק למסור את המידע, אולם בלא למוסרו
                לא ניתן יהיה לרכוש באתר.
                <br />
                <br />
                2.4. האתר לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות
                הפרטיות של האתר המהווה חלק בלתי נפרד מתקנון זה.
                <br />
                <br />
                2.5. השארת פרטים ו/או רכישה באתר, בכפוף להסכמת הגולש, כוללת, בין
                היתר, קבלת תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות
                המוצעים למשתמשים רשומים.
                <br />
                <br />
                2.6. האתר רשאי לקבוע, מעת לעת, דרכי זיהוי לכניסה לאתר ובכלל זה
                התחברות לאתר דרך הפייסבוק ו/או רשת חברתית אחרת ו/או פלטפורמה
                אחרת.
                <br />
                <br />
                2.7. האתר רשאי למנוע מכל גולש שימוש באתר לפי שיקול דעתו המוחלט.
                מבלי לגרוע מהאמור לעיל, רשאי האתר לחסום גישתו אליו בכל אחד
                מהמקרים הבאים:
                <br />
                <br />
                2.7.1. אם בעת השארת פרטים ו/או רכישה באתר נמסרו במתכוון פרטים
                שגויים;
                <br />
                <br />
                2.7.2. במקרה שנעשה שימוש באתר לביצוע או כדי לנסות לבצע מעשה בלתי
                חוקי על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי
                כאמור, או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה;
                <br />
                <br />
                2.7.3. אם הופרו תנאי תקנון זה;
                <br />
                <br />
                2.7.4. במקרה שנעשה שימוש באתר בניסיון להתחרות באתר;
                <br />
                <br />
                2.7.5. אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים לגלוש ולהשתמש
                באתר בכל דרך שהיא.
                <br />
                <br />
                3. <strong>הצטרפות בתי העסק לשירותי האתר:</strong>
                <br />
                <br />
                3.1. בתי העסק המוכרים מוצרים יכולים לבקש להצטרף לאחד ממסלולי
                האתר לפרסום ומכירת המוצרים (״<strong>המסלולים</strong>״).
                <br />
                <br />
                3.2. ההצטרפות תתבצע באמצעות הרשמה באתר. לאחר מכן, נציג האתר
                ייצור קשר עם בית העסק להשלמת תהליך ההצטרפות ופרסום המוצרים באתר.
                <br />
                <br />
                3.3. ההצטרפות תהיה בכפוף לתנאי המסלול כפי שיקבעו בהסכם ההתקשרות
                בין האתר לבין בית העסק.
                <br />
                <br />
                3.4. בכל עת האתר רשאי לשנות את מגוון המסלולים המוצעים או את
                אפיונם של המסלולים, להחליפם להמעיט מהם, להוסיף עליהם, ללא כל
                הודעה או התראה מוקדמת.
                <br />
                <br />
                3.5. לבתי העסק שיצטרפו למסלולי האתר, לא תהיה כל טענה באשר למקום
                ו/או אופן הצגת מוצריהם באתר ו/או ברשת האינטרנט.
                <br />
                <br />
                3.6. מעת לעת, האתר עשוי להציע למצטרפים למסלול הצעות, הטבות
                והנחות שונות. האתר רשאי להציע הצעות והנחות כאמור, ולשנותן או
                להפסיקן בכל עת, הכל על-פי שיקול דעתו.
                <br />
                <br />
                4. <strong>רכישה מבית עסק באתר:</strong>
                <br />
                <br />
                4.1. המוצרים המוצעים לרכישה באתר על ידי בתי העסק יופיעו בדפי
                האתר, בעמוד של בית העסק.
                <br />
                <br />
                4.2. האתר אינו מחויב, בכל דרך שהיא, לקיים מגוון כלשהו של מוצרים
                ו/או בתי העסק.
                <br />
                <br />
                4.3. ניתן לרכוש מוצרים מבתי העסק המפרסמים באתר באופן נוח ומאובטח
                באמצעות עמוד תשלום ייעודי לכל בית עסק.
                <br />
                <br />
                4.4. המחירים המופיעים בצמוד למוצרים באתר הם המחירים העדכניים כפי
                שנקבעו על ידי בית העסק. האתר לא יהיה מחויב כלפי הגולשים למחירים
                אותם הציע בית העסק, ובית העסק יהיה האחראי הבלעדי בעניין זה.
                <br />
                <br />
                4.5. בנוסף להוראות התקנון יחולו על ההתקשרות עם עסק גם הוראות
                תקנון בית העסק.
                <br />
                <br />
                4.6. הזמנות של מוצרים יטופלו ויסופקו על ידי בית העסק בהתאם
                למדיניות האספקה של בית העסק כפי שמצוין בתקנון בית העסק. תנאי
                אספקת המוצרים, לרבות מועד האספקה, הינם באחריות בית העסק ממנו
                נרכשו המוצרים והאתר לא יהיה אחראי לכל איחור ו/או עיכוב באספקה
                ו/או אי-אספקה של מוצרים.
                <br />
                <br />
                4.7. בקשה להחזר, שינוי או ביטול הזמנה של מוצרים תועבר ישירות
                לעסק ממנו נרכשו המוצרים באחד מאמצעי ההתקשרות המופיעים בעמוד
                העסק.
                <br />
                <br />
                4.8. במקרה זה יחולו תנאי בית העסק, בהתאם למדיניות הביטולים של
                העסק המופיעה בתקנון בית העסק ובכפוף לחוק הגנת הצרכן,
                התשמ"א-1981. על האתר לא תחול כל אחריות בגין ביטול רכישה של
                מוצרים שנרכשו מבית עסק המפרסם באתר.
                <br />
                <br />
                5. <strong>אחריות האתר:</strong>
                5.1. אין לראות במידע המופיע באתר משום הבטחה לתוצאה כלשהי ו/או
                אחריות לאופן הפעילויות של המוצרים המוצעים בו. האתר לא יהיה אחראי
                לשום נזק, ישיר או עקיף, אשר ייגרם לגולש כתוצאה מהסתמכות על מידע
                המופיע באתר ו/או בקישורים לאתרים אחרים ו/או כל מקור מידע פנימי
                ו/או חיצוני אחר ו/או שימוש במוצרים ובשירותים אשר מוצגים על ידו.
                <br />
                <br />
                5.2. המוצרים המופיעים באתר מסופקים על ידי בתי העסק, צדדים
                שלישיים, שההתקשרות עמם נעשית על אחריותו הבלעדית של הלקוח ולא
                תהיה ללקוח כל טענה כלפי האתר, מנהליו, עובדיו או מי מטעמו והם לא
                יהיו אחראים, באופן ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או
                לכל נזק אחר או לכל הפסד אחר, אשר יגרם כתוצאה מרכישת מוצר מבתי
                העסק המפורסמים באתר, אפילו אם האתר הזהיר, המליץ או ייעץ לעניין
                מסוים או על שירות מסוים ובשום מקרה האתר לא יהווה תחליף או יישא
                באחריות המקצועית של בתי העסק על המוצרים המוצעים לרכישה על ידם,
                טיב המוצרים, אספקת המוצרים, שירותם וכיו״ב.
                <br />
                <br />
                5.3. הלקוחות הגולשים באתר מאשרים ומצהירים כי הם יישאו באחריות
                לפנות ולדרוש מבית העסק פיצוי בגין כל נזק, ישיר או עקיף, אשר
                ייגרם להם בעקבות שימוש במוצר.
                <br />
                <br />
                5.4. אין להסתמך על פירוט או תיאור מוצר המופיע באתר. יש לקרוא את
                המופיע על גבי אריזת המוצר לפני השימוש בו ולקרוא בעיון את הוראות
                המוצר (לרבות גיל מינימלי לשימוש, תחזוקת המוצר וכד') והאחריות
                הינה על הלקוח.
                <br />
                <br />
                5.5. תמונות המוצרים באתר מוצגות לצורכי המחשה בלבד. ייתכנו הבדלים
                במראה, בגוון, בגודל, וכיו"ב בין המוצר, כפי שהוא מוצג באתר, לבין
                המוצר שיסופק ללקוח. טעות סופר בתיאור מוצר ו/או מחירו לא תחייב את
                האתר.
                <br />
                <br />
                5.6. מידע ומצגים אודות מוצרים המוצגים באתר, שמקורם בבתי העסק
                שמוצריהם מופיעים באתר וכל תוכן ביחס למוצרים נמצאים באחריותם
                הבלעדית של בתי העסק כאמור, ועל כן מובן שלאתר אין כל אחריות בגין
                מידע מעין זה, ואין האתר ערב למידת הדיוק של מידע זה.
                <br />
                <br />
                5.7. האתר אינו אחראי על מספר הלקוחות שיפנו לבית עסק ו/או ירכשו
                מבית העסק, ולבתי העסק המשתמשים בשירותי האתר לא תהיה כל טענה ו/או
                דרישה ו/או תביעה כלפי האתר בגין מספר הלקוחות שיפנו אליהם ו/או
                ירכשו מהם.
                <br />
                <br />
                5.8. האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש
                והוצאות שייגרמו לגולשים ו/או לצדדים שלישיים כלשהם בעקבות שימוש
                או הסתמכות על כל תוכן, מידע, נתון, מצג, תמונה, וידאו, אודיו,
                פרסומת, מוצר, שירות וכו' המופעים באתר. כל הסתמכות כאמור נעשית
                על-פי שיקול דעתו ואחריותו הבלעדית של הגולש באתר.
                <br />
                <br />
                5.9. האתר בשום מקרה לא יהיה אחראי לנזק שנגרם לגולש האתר באמצעות
                יצירת קשר עם בתי העסק המפרסמים באתר ו/או שותפיו העסקיים של האתר.
                <br />
                <br />
                5.10. האתר ממליץ לגולשים באתר לנהוג כצרכנים נבונים וזהירים,
                ולקרוא בעיון את המידע המוצג באתר ובכלל זה את המידע ביחס למוצר
                עצמו, תיאורו והתאמתו, כמתואר באתר.
                <br />
                <br />
                5.11. התכנים באתר ניתנים לשימוש כמות שהם (<strong>AS IS</strong>
                ). לא ניתן להתאימם לצרכיו של כל אדם ואדם. לא תהיה לגולש כל טענה,
                תביעה או דרישה כלפי האתר בגין תכונות של התכנים, יכולותיהם,
                מגבלותיהם ו/או התאמתם לצרכיו.
                <br />
                <br />
                5.12. האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות
                שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם
                או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע.
                <br />
                <br />
                5.13. השימוש באתר ייעשה על אחריותו הבלעדית והמלאה של כל גולש. כל
                החלטה שתתקבל ביחס לתכנים שיתפרסמו באתר הינה באחריותו המלאה.
                <br />
                <br />
                6. <strong>מדיניות פרסום מוצרים באתר:</strong>
                <br />
                <br />
                6.1. האתר שומר לעצמו את הזכות לא לאשר פרסום ו/או מכירת מוצר
                שאינו ראוי ו/או אינו עומד בסטנדרטים של האתר ו/או שאסור לפרסום
                ו/או למכירה על פי חוקי מדינת ישראל.
                <br />
                <br />
                6.2. האתר רשאי להסיר ו/או לערוך כל פרסום באתר. כמו כן האתר שומר
                לעצמו את הזכות לדרוש מבית עסק המפרסם באתר אימות בעלות ו/או אישור
                שיווק ו/או יבוא על המוצרים אותם הוא מוכר.
                <br />
                <br />
                6.3. מבלי לגרוע מהאמור, בתי העסק המצטרפים לאתר ו/או מפרסמים
                באתר, מסכימים, מתחייבים ומאשרים כי ידוע להם:
                <br />
                <br />
                6.3.1. שהאתר ניתן לשימוש כפי שהוא ושהשימוש בשירותיו הוא באחריות
                בית העסק בלבד;
                <br />
                <br />
                6.3.2. שאסור לבית עסק למכור ו/או לאפשר שימוש באתר לבית עסק אחר
                ו/או אדם אחר ו/או כל ישות אחרת;
                <br />
                <br />
                6.3.3. שהאחריות לתיאור המוצרים שיועלה יהיה באחריותו הבלעדית של
                בית העסק, לרבות שינויים ועריכות שיבוצעו על ידי האתר;
                <br />
                <br />
                6.3.4. שהאתר, מנהליו, עובדיו או מי מטעמו לא יהיו אחראים, באופן
                ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או לכל נזק אחר או לכל
                הפסד אחר, אשר יגרם כתוצאה משימוש באתר, אפילו אם האתר הזהיר,
                המליץ או ייעץ לעניין מסוים ובשום מקרה האתר לא יהווה תחליף או
                יישא באחריות בית העסק לשירותיו ומוצריו.
                <br />
                <br />
                6.4. בעת פרסום מוצרים ו/או תכנים באתר, חלה על המפרסם האחריות
                המלאה והבלעדית לכל תוצאה שתנבע מהפרסום ועליו להקפיד שתכנים כאלה
                יהיו חוקיים. בין השאר ולמען הדוגמה בלבד, אין לפרסם באתר את
                התכנים הבאים:
                <br />
                <br />
                6.4.1. כל תוכן הידוע כשקרי, מטעה או מסולף;
                <br />
                <br />
                6.4.2. כל תוכן הפוגע בזכויות קנייניות של אחרים או מפר אותן לרבות
                זכויות יוצרים וסימני מסחר;
                <br />
                <br />
                6.4.3. כל תוכן העלול להטעות צרכן;
                <br />
                <br />
                6.4.4. כל תוכן פורנוגראפי או בעל אופי מיני בוטה;
                <br />
                <br />
                6.4.5. כל תוכן הנוגע לקטינים ומזהה אותם, את פרטיהם האישיים או את
                מענם ודרכי ההתקשרות עימם;
                <br />
                <br />
                6.4.6. כל תוכן המזהה אישית אנשים אחרים, מבלי שנתנו את הסכמתם
                לפרסום זהותם;
                <br />
                <br />
                6.4.7. כל תוכנת מחשב, קוד מחשב או יישום הכוללים נגיף מחשב
                ("וירוס") לרבות תוכנות עוינות ויישומים מזיקים למיניהם;
                <br />
                <br />
                6.4.8. כל תוכן המהווה לשון הרע על אדם, או הפוגע בפרטיותו או בשמו
                הטוב;
                <br />
                <br />
                6.4.9. כל תוכן בעל אופי או תוכן טורדני, מעליב, עוין, מאיים או גס
                רוח;
                <br />
                <br />
                6.4.10. כל תוכן שיש בו, או הוא מעודד לגזענות או אפליה פסולה על
                בסיס כלשהו;
                <br />
                <br />
                6.4.11. כל תוכן המעודד ביצוע עבירה פלילית או עלול להוות בסיס
                לתביעה אזרחית;
                <br />
                <br />
                6.4.12. כל תוכן המנוגד לכללי השימוש המקובלים באינטרנט או העלול
                לגרום נזק או לפגוע במשתמשי האינטרנט בכלל ובמשתמשי האתר בפרט וכל
                תוכן שנועד לקדם את האינטרסים של מתחרי האתר.
                <br />
                <br />
                6.5. האתר רשאי לסרב לפרסם או למחוק לאלתר כל תוכן שנמסר לפרסום
                בכל עת שימצא כי הופרו תנאי התקנון או שתכנים שפורסמו פוגעים או
                עלולים לפגוע בשירותים הניתנים באתר, במשתמשיו, באתר או במי מטעמו.
                בנוסף, האתר יהיה רשאי במקרה זה למנוע מעסק מלפרסם תכנים נוספים
                באתר ו/או לחסום את גישתו לאתר. הוראות סעיף זה מוסיפות על זכויות
                האתר על פי כל דין.
                <br />
                <br />
                6.6. מסירת תכנים ו/או תגובות לפרסום באתר מהווה אישור כי המפרסם
                הוא בעל כל הזכויות בהם וכי הוא רשאי למוסרם לפרסום ו/או הוא בעל
                הרשאה כדין מבעל הזכויות, המתירה לו למסור את התכנים לפרסום באתר.
                מובהר כי המפרסם מוחזק כאחראי לשאת בכל נזק ו/או הפסד ו/או תביעה
                ו/או דרישה שיוגשו נגד האתר בגין שימוש ללא היתר בתכנים כאמור.
                <br />
                <br />
                6.7. יובהר כי כל זכויות היוצרים והקניין הרוחני של התמונות
                המצולמות על ידי האתר הם בבעלות האתר בלבד, אף שצולמו עבור העסק.
                כל שימוש בתמונות שצולמו על ידי האתר ייעשה באישור מראש בלבד של
                האתר.
                <br />
                <br />
                6.8. במסירת התכנים לפרסום מוקנה לאתר רישיון חינם, כלל עולמי
                ובלתי מוגבל בזמן, להעתיק, לשכפל, להפיץ, לשווק, למסור לציבור,
                לעבד, לערוך, לתרגם ולהשתמש בתכנים כאמור בכל דרך נוספת, לפי שיקול
                דעתו, באתר, בדברי דפוס, לרבות בעיתונים, מגזינים, כתבי עת, ספרים
                וכיו"ב, במדיות דיגיטליות, לרבות באתרי אינטרנט נוספים, בארכיבים
                דיגיטאליים ובשירותים הניתנים ברשתות תקשורת, לרבות תקשורת חוטית,
                אל חוטית, סלולארית, כבלים ולווין.
                <br />
                <br />
                7. <strong>הרשאה לדיוור, פרסומים ופרסומת:</strong>
                <br />
                <br />
                7.1. גולש שהשאיר פרטים באתר ומצורף לרשימת הדיוור של האתר, מאשר
                שימוש בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות שיבצע האתר
                מעת לעת.
                <br />
                <br />
                7.2. על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות
                בתקנון להלן.
                <br />
                <br />
                7.3. אין להשאיר פרטים של אדם אחר באתר שלא בהסכמתו ו/או ללא
                נוכחותו מול המסך בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי
                התקנון.
                <br />
                <br />
                7.4. בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם
                פרטי, שם משפחה, וכן כתובת דואר אלקטרוני פעילה. מסירת פרטים
                חלקיים או שגויים עלולים למנוע את האפשרות להשתמש בשירות ולסכל
                יצירת קשר במקרה הצורך. במקרה של שינוי פרטים יש לעדכנם באתר.
                <br />
                <br />
                7.5. מובהר כי אין חובה על-פי חוק למסור פרטים באתר, אולם בלא
                למוסרם לא ניתן יהיה לקבל תוכן שיווקי ועדכונים מהאתר.
                <br />
                <br />
                7.6. האתר לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות
                הפרטיות של האתר המהווה חלק בלתי נפרד מהתקנון.
                <br />
                <br />
                7.7. השארת פרטים באתר ואישור לקבלת תוכן שיווקי כוללת, בין היתר,
                קבלת תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים
                למשתמשים רשומים.
                <br />
                <br />
                7.8. אישור דיוור (קבלת תוכן שיווקי) כאמור, מהווה את הסכמתו של
                הגולש למשלוח דברי פרסומת על-פי חוק התקשורת (בזק ושידורים) (תיקון
                מס' 40) התשס"ח – 2008 ("חוק התקשורת").
                <br />
                <br />
                7.9. מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור
                באמצעות לחיצה על "להסרה מרשימת התפוצה" שמופיע בתחתית כל דיוור
                שנשלח או באמצעות פנייה בדוא"ל לאתר. כל עוד לא הסירו עצמו הנרשם
                מרשימת הדיוור כאמור, רשאי האתר, בכפוף לחוק התקשורת, להעביר לנרשם
                דיוור ישיר כאמור.
                <br />
                <br />
                7.10. אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות
                לשירות המוצע בו.
                <br />
                <br />
                7.11. הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא,
                ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או
                לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע.
                <br />
                <br />
                7.12. הגולש מאשר כי לא תהיה לו כל טענה בקשר למודעות פרסום ו/או
                פרסומות המוצגות באתר, לרבות בקשר למיקומן באתר. מובהר כי בכל
                הנוגע למודעות פרסום המוצגות בחסות צד שלישי, לאתר אין כל התערבות
                בבחירת הפרסומות המוצגות, אמיתות תוכנן וסדר הופעתן.
                <br />
                <br />
                8. <strong>השימוש באתר:</strong>
                <br />
                <br />
                8.1. השימוש באתר מותר למטרות פרטיות ואישיות בלבד. אין להעתיק
                ולהשתמש או לאפשר לאחרים להשתמש, בכל דרך אחרת בתכנים מתוך האתר,
                לרבות באתרי אינטרנט אחרים, בפרסומים אלקטרוניים, בפרסומי דפוס
                וכיו"ב, לכל מטרה, בין מסחרית ובין שאינה מסחרית, שאיננה לצורך
                שימוש אישי ופרטי, למעט בכפוף לקבלת אישור ו/או הסכמה מפורשת מראש
                ובכתב.
                <br />
                <br />
                8.2. אין להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר,
                לרבות תוכנות מסוג Crawlers Robots וכדומה, לשם חיפוש, סריקה,
                העתקה או אחזור אוטומטי של תכנים מתוך האתר. בכלל זה, אין ליצור
                ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו
                תכנים מהאתר.
                <br />
                <br />
                8.3. אין להציג תכנים מהאתר בכל דרך שהיא ובכלל זה באמצעות כל
                תוכנה, מכשיר, אביזר או פרוטוקול תקשורת המשנים את עיצובם באתר או
                מחסירים מהם תכנים כלשהם ובפרט פרסומות ותכנים מסחריים.
                <br />
                <br />
                8.4. אין לקשר לאתר מכל אתר המכיל תכנים פורנוגראפיים, תכנים
                המעודדים לגזענות או להפליה פסולה, או המנוגדים לחוק, או שפרסומם
                מנוגד לחוק או המעודדים פעילות המנוגדת לחוק.
                <br />
                <br />
                8.5. אין לקשר לתכנים מהאתר, שאינם דף הבית של האתרים ("
                <strong>קישור עמוק</strong>") ואין להציג, או לפרסם תכנים כאמור
                בכל דרך אחרת, אלא אם הקישור העמוק יהיה לדף אינטרנט באתר במלואו
                וכפי שהוא ("<strong>AS IS</strong>") כך שניתן יהיה לצפות ולהשתמש
                בו באופן הזהה לחלוטין לשימוש ולצפייה בו באתר. במסגרת זו, חל
                איסור לקשר לתכנים מהאתר, במנותק מדפי האינטרנט שבהם הם מופיעים
                באתרים (לדוגמה: אסור לקשר במישרין לתמונה או לקובץ גרפי באתר, אלא
                לעמוד המלא שבו הם מופיעים). כמו כן על כתובתו המדויקת של דף
                האינטרנט באתר להופיע במקום הרגיל המיועד לכך בממשק המשתמש,
                לדוגמה: בשורת הכתובת ("<strong>Status Bar</strong>") בדפדפן של
                המשתמש. אין לשנות, לסלף או להסתיר כתובת זו ואין להחליפה בכל
                כתובת אחרת;
                <br />
                <br />
                8.6. האתר רשאי לדרוש ביטול כל קישור עמוק כאמור לפי שיקול דעתו
                הבלעדי ובמקרה זה לא תעמוד כל טענה, דרישה או תביעה כלפי האתר
                בעניין זה.
                <br />
                <br />
                8.7. האתר לא יישא בכל אחריות לכל נזק שייגרם כתוצאה מכל קישור
                לתכנים מהאתר ומכל הצגה או פרסום של תכנים כאמור בכל דרך אחרת.
                האחריות המלאה והבלעדית לכל קישור, הצגה או פרסום של התכנים, היא
                על מבצע הקישור בלבד.
                <br />
                <br />
                8.8. על הגולש לשפות את האתר, עובדיו, מנהליו, שותפיו העסקיים או
                מי מטעמו בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו
                להם - ובכלל זה שכ"ט עו"ד והוצאות משפט עקב הפרת תקנון זה. בנוסף,
                ישפה המשתמש את האתר, עובדיו, מנהליו או מי מטעמו בגין כל טענה,
                תביעה ו/או דרישה שתועלה נגדם על ידי צד שלישי כלשהו כתוצאה מתכנים
                שנמסרו על ידו לפרסום באתר וכתוצאה מקישורים שביצע לאתר.
                <br />
                <br />
                9. <strong>שינויים באתר, תקלות והפסקות שירות:</strong>
                <br />
                <br />
                9.1. מבלי לגרוע מהאמור לעיל, האתר יוכל לשנות מעת לעת את מבנה
                האתר, ו/או המראה ו/או העיצוב של האתר, את היקפם וזמינותם של
                השירותים באתר, יהיה רשאי לגבות תשלום בעד תכנים ושירותים כאלה או
                אחרים על פי החלטתו. כמו כן, האתר יהיה רשאי לשנות כל היבט אחר
                הכרוך באתר והכל, בלא צורך להודיע על כך מראש.
                <br />
                <br />
                9.2. שינויים כאמור יבוצעו, בין השאר, בהתחשב באופי הדינאמי של רשת
                האינטרנט ובשינויים הטכנולוגיים והאחרים המתרחשים בה. מטבעם,
                שינויים מסוג זה עלולים להיות כרוכים בתקלות ו/או לעורר בתחילה אי
                נוחות וכיו"ב. לא תהיה לגולשים באתר כל טענה, תביעה ו/או דרישה
                כלפי האתר בגין ביצוע שינויים כאמור ו/או תקלות שיתרחשו אגב
                ביצועם.
                <br />
                <br />
                9.3. האתר אינו מתחייב ששירותי האתר לא יופרעו, יינתנו כסדרם או
                בלא הפסקות, יתקיימו בבטחה וללא טעויות ויהיו חסינים מפני גישה
                בלתי מורשית למחשבי האתר או מפני נזקים, קלקולים, תקלות או כשלים -
                והכל, בחומרה, בתוכנה, בקווי ובמערכות תקשורת אצל האתר או אצל מי
                מספקיו.
                <br />
                <br />
                10. <strong>קניין רוחני:</strong>
                <br />
                <br />
                10.1. כל זכויות היוצרים והקניין הרוחני הם בבעלות האתר בלבד, או
                בבעלות צד שלישי, שהרשה לאתר לעשות שימוש על פי דין בתכנים אלו
                ובכלל זה שותפיה העסקיים של האתר.
                <br />
                <br />
                10.2. אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, להעביר
                לציבור, לשנות, לעבד, ליצור יצירות נגזרות, למכור או להשכיר כל חלק
                מן הנ"ל, בין ישירות ובין באמצעות או בשיתוף צד שלישי, בכל דרך או
                אמצעי בין אם אלקטרוניים, מכאניים, אופטיים, אמצעי צילום או הקלטה,
                או בכל אמצעי ודרך אחרת, בלא קבלת הסכמה בכתב ומראש מהאתר או מבעלי
                הזכויות האחרים, לפי העניין, ובכפוף לתנאי ההסכמה )ככל שתינתן).
                הוראה זו תקפה גם ביחס לכל עיבוד, עריכה או תרגום שנעשו על ידי
                האתר לתכנים שהוזנו או נמסרו על ידי הגולשים לאתר.
                <br />
                <br />
                10.3. אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או
                לשבש כל הודעה או סימן בעניין זכויות קניין רוחני, לדוגמה: סימון
                זכויות היוצרים ,© או סימן מסחר ®, הנלווים לתכנים שיעשה בהם
                שימוש.
                <br />
                <br />
                10.4. סימני המסחר, צילומים, תמונות תכנים ומודעות הפרסומת של
                שותפיה העסקיים של האתר הינם קניינם של מפרסמים אלה בלבד. גם בהם
                אין לעשות בהם שימוש בלא הסכמת המפרסם מראש ובכתב.
                <br />
                <br />
                11. <strong>סמכות שיפוט:</strong>
                <br />
                <br />
                11.1. על תקנון זה יחולו אך ורק דיני מדינת ישראל, אולם לא תהיה
                תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם.
                <br />
                <br />
                11.2. לבתי המשפט במחוז _______ תהיה סמכות שיפוט ייחודית בכל
                עניין הנובע ו/או הקשור לתקנון זה.
                <br />
                <br />
                12. <strong>מדיניות פרטיות:</strong>
                <br />
                <br />
                12.1. האתר מכבד את פרטיות הלקוחות.
                <br />
                <br />
                12.2. על מנת לספק שירות איכותי, אנו עשויים להשתמש בנתונים
                האישיים שלך, ובין היתר, מידע על השימוש שלך באתר ומידע על המכשיר
                הנייד שלך או המחשב ("<strong>המידע הנאסף באתר</strong>"). המידע
                הנאסף באתר עשוי לשמש את האתר לצרכים הבאים:
                <br />
                <br />
                12.2.1. לספק לך שירותים ולשפר את האתר ו/או את השירותים;
                <br />
                <br />
                12.2.2. תפעולו התקין של האתר;
                <br />
                <br />
                12.2.3. לנתח את ולנהל את האתר באופן תקין;
                <br />
                <br />
                12.2.4. שיפור שירות הלקוחות של האתר;
                <br />
                <br />
                12.2.5. ליצירת קשר או לספק לך נתונים בקשר לאתר או לשירות.
                <br />
                <br />
                12.3. בעת השימוש באתר, האתר עשוי לאסוף מידע מסוים באופן אוטומטי.
                לדוגמה:
                <br />
                <br />
                12.3.1. כתובת ה- IP שלך, פרוטוקול האינטרנט, ספק האינטרנט או
                הדפדפן וסוג המכשיר ממנו אתה גולש;
                <br />
                <br />
                12.3.2. הקלטת הפעילות שלך באתר או תרשים העמודים בהם ביקרת;
                <br />
                <br />
                12.3.3. שימוש בעוגיות על מנת לזהות את המכשיר ממנו אתה גולש. קובץ
                עוגיות הם קובץ טקסט קטן שאתר, יישום מקוון או דואר אלקטרוני
                עשויים לשמור בדפדפן האינטרנט ו/או בכונן הקשיח של המחשב לשימוש
                בביקורים הבאים באתר;
                <br />
                <br />
                12.3.4. מידע שתזין, תשתף או שניתן להשיג מהשימוש שלך באתר.
                <br />
                <br />
                12.4. אנו עשויים לשתף את המידע האישי שלך (למעט מידע רגיש כגון
                פרטי אשראי ומספרי תעודות זהות) עם צדדים שלישיים, כולל עם שותפינו
                העסקיים ונותני שירותים.
                <br />
                <br />
                12.5. דוגמאות לפעולות שנותני שירותים עשויים לבצע הכרוכים במידע
                שלך:
                <br />
                <br />
                12.5.1. לפתח ולתחזק את האתר;
                <br />
                <br />
                12.5.2. לצבור מידע על לקוחות ו/או גולשים ולשפר את שירות הלקוחות.
                לאחר מכן, האתר עשוי לשתף מידע כאמור עם שותפי שיווק פוטנציאליים
                ומפרסמים;
                <br />
                <br />
                12.5.3. לצרכים סטטיסטים – אנו מספקים מידע אישי לגופים וחברות
                שאנו נותנים בהן אמון כדי שיעבדו את המידע עבורנו לפי הוראות האתר
                ובאופן העולה בקנה אחד עם תקנון זה ומדיניות האתר. ככלל, וככל שלא
                ניתנה הסכמה מראש למסירת מידע אישי, מידע המועבר לצרכים סטטיסטים
                אינו כולל פרטים מזהים.
                <br />
                <br />
                12.6. במידה והאתר ימוזג לתוך פעילות גוף אחר או אם האתר יעבור
                לבעלות תאגיד אחר ניתן יהיה להעביר לתאגיד החדש את המידע הקיים
                באתר, אבל רק במקרה שהתאגיד יתחייב לשמור על תנאי תקנון זה.
                <br />
                <br />
                12.7. לנותני שירותים ושותפים עסקיים כאמור ניתנת גישה לכל או לחלק
                מהמידע שלך, והם עשויים להשתמש בעוגיות (כהגדרתן לעיל) או
                בטכנולוגיית איסוף אחרות.
                <br />
                <br />
                12.8. חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת
                ונחושה מצד גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט והאתר לא
                מתחייב שהשירותים באתר יהיו חסינים באופן מוחלט מפני גישה בלתי
                מורשית למידע הנאסף בו.
                <br />
                <br />
                13. <strong>צרו קשר:</strong>
                <br />
                <br />
                13.1. האתר מקפיד על קיום הוראות החוק ומכבד את זכותם של משתמשי
                האתר ואחרים לפרטיות ולשם טוב. אם אתה סבור כי פורסם באתר תוכן
                הפוגע בך מטעם כלשהו, נא פנה אלינו לפי הפרטים שלהלן ואנו נשתדל
                לטפל בפנייתך בכל ההקדם. פניות כאמור ניתן להעביר באמצעים הבאים:
                כתובת: _____, _____;
                <br />
                <br />
                טלפון: 0524-262064;
                <br />
                <br />
                דוא"ל: ATHAGIT@GMAIL.COM
                <br />
                <br />
                עדכון אחרון: יוני 2022
              </div>

              <div className="form-credentials-section__check">
                <div className="checkbox">
                  <input
                    id="c_1"
                    className="checkbox__input"
                    type="checkbox"
                    value="1"
                    name="isAcceptedTerms"
                    onChange={(e) => {
                      if (
                        e.target.checked &&
                        e.target.nextElementSibling.classList.contains(
                          "input-error"
                        )
                      ) {
                        e.target.nextElementSibling.classList.remove(
                          "input-error"
                        );
                      }
                    }}
                  />
                  <label htmlFor="c_1" className="checkbox__label">
                    <span className="checkbox__text">אני מאשר.ת</span>
                  </label>
                </div>
              </div>

              <div className="form-credentials-section__buttons-container">
                <button type="submit" className="btn">
                  אישור
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
      {registerStep.afterRegister ? (
        <>
          <button
            type="button"
            onClick={goToSubmitTermsForm}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title">
            תודה {registerFormInfo.fullname} הנך רשום/ה לאתר
          </h1>

          <div className="credentials-section__image-icon">
            <img src={imgAfterRegister} alt="" />
          </div>

          <div className="credentials-section__logo">
            <img src={logoImg} alt="logo" />
          </div>

          <div className="form-credentials-section__buttons-container">
            <NavLink to="/" className="btn">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                >
                  <path
                    id="Объединение_76"
                    data-name="Объединение 76"
                    d="M0,373v-2.375c0-2.613,4.275-4.751,9.5-4.751s9.5,2.138,9.5,4.751V373Zm4.75-14.25A4.75,4.75,0,1,1,9.5,363.5,4.75,4.75,0,0,1,4.75,358.75Z"
                    transform="translate(1 -353)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              מאמץ אחרון לפני הזמנת החברים
            </NavLink>
            <NavLink to="/" className="btn-white mobile">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.516"
                  height="25.445"
                  viewBox="0 0 25.516 25.445"
                >
                  <path
                    id="home_1_"
                    data-name="home (1)"
                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                    transform="translate(0.771 0.601)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.2"
                  ></path>
                </svg>
              </div>
              דף הבית
            </NavLink>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="credentials-section__progress">
        <div className="percent" style={{ right: `${progress}%` }}>
          {`${progress}%`}
        </div>
        <div className="bar">
          <div className="background"></div>
          <div className="percent-line" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </>
  );
}

export default RegisterReferalWishlist;
