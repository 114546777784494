import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import RemoveProductPopup from "../Popups/RemoveProductPopup";

export default function SelectedGiftsPopup({
  updateSelectedGiftsState,
  addItemHandler,
  currWishlist,
  currUserObject,
  setCurrWishlist,
  setUpdateTheWishlist,
  updateSetPopup,
  setPopup
}) {
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const [isMobile, setIsMobile] = useState(false);
  const [showRemoveProductPopup, setShowRemoveProductPopup] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);

  useEffect(() => {
    if ( showRemoveProductPopup) {
     document.documentElement.classList.add("popup-show", "loaded");
     // setPopup(true);
    } else {
      document.documentElement.classList.remove("popup-show", "loaded", "lock");
   //   console.log("(((((((((",document.documentElement.classList);
    }
  }, [showRemoveProductPopup]);
 
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const closePopupHandler = () => {
    setPopup(false);
    updateSetPopup();
    updateSelectedGiftsState(false);
  };

  const openRemoveProductPopup = (product) => {
    setProductToRemove(product);
    setShowRemoveProductPopup(true);
  };

  const confirmRemoveProduct = () => {
    if (!productToRemove) return;

    const wishListItemId = productToRemove.wishListItemId;

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveWishlistItem?userId=${currUserObject?.user?.id}&wishListItemId=${wishListItemId}`
      )
      .then((response) => {
        if (response.data.result) {
          setCurrWishlist((prevObj) => {
            const newItemsDetailsList = prevObj?.whishlistItemsDetailsList?.filter(
              (item) => item.wishListItemId !== wishListItemId
            );
            return {
              ...prevObj,
              whishlistItemsDetailsList: newItemsDetailsList,
            };
          });

          setUpdateTheWishlist(true);
          let totalWishlistPrice = 0;
          const updatedDetailsList = currWishlist.whishlistItemsDetailsList.filter(
            (item) => item.wishListItemId !== wishListItemId
          );
          updatedDetailsList.forEach((prod) => {
            totalWishlistPrice += Number(prod.price);
          });
          addItemHandler((prev) => ({
            ...prev,
            wishlistPrice: totalWishlistPrice,
            wishlistProductsNumber: updatedDetailsList.length,
          }));
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Error, please try again");
      })
      .finally(() => {
        setShowRemoveProductPopup(false);
        setProductToRemove(null);
      });
  };

  const cancelRemoveProduct = () => {
    setShowRemoveProductPopup(false);
    setProductToRemove(null);
  };


  return (
    <div
      id="already-bought-popup"
      style={{
        flex: '0 0 9.0625vw',
        fontSize: isMobile ? '0.905rem' : 'initial',
      }}
      aria-hidden="true"
      className="popup popup_show credentials-section__form-wrapper"
      data-fullscreen=""
    >
      <div className="popup__wrapper">
        <div
          className="popup__content"
          style={{ maxWidth: !isMobile ? '35.927083vw' : '100vw' }}
        >
          <button
            data-close=""
            type="button"
            className="popup__close already-bought"
            onClick={() => closePopupHandler()}
          />
          <div className="popup__body">
            <h1 className="popup__title" style={{ color: '#ec1b53' }}>
              מתנות שבחרת
            </h1>
            <ul className="popup__list hi">
              {currWishlist?.whishlistItemsDetailsList
                ?.slice()
                .reverse()
                .map((person, index) => (
                  <li
                    key={index}
                    className="popup__list-item"
                    style={{ marginTop: '4px', marginLeft: '13px'}}
                  >
                    <div className="avatar">
                      {/* If you have an avatar image */}
                    </div>
                    <p className="my-event-single__image-ibg">
                    {person?.image && (
  <>
    {person.manuallAdd === true ? (
      // If manually added, transform the image URL
      <img
        src={`https://server.yyyshopping.com${person.image.replace(/^,/, "")}`}
        alt="Person"
      />
    ) : (
      // Otherwise, use the original image URL
      <img src={person.image} alt="Person" />
    )}
  </>
)}
                    </p>
                    <p className="text name">{person?.productName}</p>
                    <p className="text product">₪{person?.price}</p>
                    <button
                      type="button"
                      className="button delete"
                      onClick={() => openRemoveProductPopup(person)}
                    >
                      {/* Trash icon SVG here */}
                      <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    width="25.515"
                    height="20"
                    viewBox="0 0 31.515 37"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Прямоугольник_1981"
                          data-name="Прямоугольник 1981"
                          width="29.208"
                          height="25.38"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect
                          id="Прямоугольник_1983"
                          data-name="Прямоугольник 1983"
                          width="18.458"
                          height="8.075"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Trash_can"
                      data-name="Trash can"
                      transform="translate(0 -2.813)"
                    >
                      <g
                        id="Прямоугольник_1980"
                        data-name="Прямоугольник 1980"
                        transform="translate(0 10.354)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      >
                        <rect
                          width="31.515"
                          height="4.614"
                          rx="2.307"
                          stroke="none"
                        />
                        <rect
                          x="0.7"
                          y="0.7"
                          width="30.115"
                          height="3.214"
                          rx="1.607"
                          fill="none"
                        />
                      </g>
                      <line
                        id="Линия_491"
                        data-name="Линия 491"
                        x2="2.092"
                        y2="15.061"
                        transform="translate(8.884 17.722)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <line
                        id="Линия_492"
                        data-name="Линия 492"
                        y2="15.061"
                        transform="translate(15.605 17.722)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <line
                        id="Линия_493"
                        data-name="Линия 493"
                        x1="1.935"
                        y2="14.733"
                        transform="translate(19.953 17.703)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <g
                        id="Группа_масок_833"
                        data-name="Группа масок 833"
                        transform="translate(1.154 14.433)"
                        clipPath="url(#clip-path)"
                      >
                        <g
                          id="Контур_8526"
                          data-name="Контур 8526"
                          transform="translate(8.612 -4.365)"
                          fill="none"
                        >
                          <path
                            d="M-5.19,0H16.826a1.918,1.918,0,0,1,1.81,2.011L16.524,26.479a1.918,1.918,0,0,1-1.81,2.011H-3.079a1.918,1.918,0,0,1-1.81-2.011L-7,2.011A1.918,1.918,0,0,1-5.19,0Z"
                            stroke="none"
                          />
                          <path
                            d="M -5.190471649169922 1.399999618530273 C -5.375249862670898 1.399999618530273 -5.580936431884766 1.638710021972656 -5.598752975463867 1.965187072753906 L -3.488882064819336 26.41834831237793 L -3.488882064819336 26.4786376953125 C -3.488882064819336 26.82835960388184 -3.272671699523926 27.08967971801758 -3.079351425170898 27.08967971801758 L 14.71437835693359 27.08967971801758 C 14.9076976776123 27.08967971801758 15.1239185333252 26.82835960388184 15.1239185333252 26.4786376953125 L 15.1239185333252 26.41834831237793 L 17.23379135131836 1.965187072753906 C 17.21597290039062 1.638711929321289 17.01027488708496 1.399999618530273 16.82549858093262 1.399999618530273 L -5.190471649169922 1.399999618530273 M -5.190471649169922 -1.9073486328125e-06 L 16.82549858093262 -1.9073486328125e-06 C 17.82487869262695 -1.9073486328125e-06 18.63503837585449 0.9003696441650391 18.63503837585449 2.011037826538086 L 16.52391815185547 26.4786376953125 C 16.52391815185547 27.58930969238281 15.71375846862793 28.48967933654785 14.71437835693359 28.48967933654785 L -3.079351425170898 28.48967933654785 C -4.07872200012207 28.48967933654785 -4.888881683349609 27.58930969238281 -4.888881683349609 26.4786376953125 L -7.000001907348633 2.011037826538086 C -7.000001907348633 0.9003696441650391 -6.189842224121094 -1.9073486328125e-06 -5.190471649169922 -1.9073486328125e-06 Z"
                            stroke="none"
                            fill="#3c9e1c"
                          />
                        </g>
                      </g>
                      <g
                        id="Группа_масок_834"
                        data-name="Группа масок 834"
                        transform="translate(6.529 2.813)"
                        clipPath="url(#clip-path-2)"
                      >
                        <g
                          id="Прямоугольник_1982"
                          data-name="Прямоугольник 1982"
                          transform="translate(0 3.461)"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        >
                          <rect
                            width="10.304"
                            height="2.922"
                            rx="3.461"
                            stroke="none"
                          />
                          <rect
                            x="0.7"
                            y="0.7"
                            width="15.904"
                            height="5.522"
                            rx="2.761"
                            fill="none"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>


      {showRemoveProductPopup && (
        <RemoveProductPopup
        onConfirm={confirmRemoveProduct}
        onCancel={cancelRemoveProduct}
        />
      )}    </div>
  );
}
