import React from "react";

function SubmitAdressPopup({
  closeShowAdressPopup,
  currUserObject,
  OpenShowEditAdressPopup,
}) {
  return (
    <div

    className="popup-model"

    >
      {/* Popup container */}
      <div
            className="popup-container "

      >
        {/* Close button */}
        <button
          onClick={closeShowAdressPopup}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ✖
        </button>
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.5rem", color: "green" }}>הכתובת שלך:</h1>
          <p style={{ fontSize: "1rem", margin: "0.5rem 0" }}>
            {currUserObject?.user?.street}{" "}
            {currUserObject?.user?.apartmentNumber},{" "}
            {currUserObject?.user?.city}
          </p>
          <div style={{ marginTop: "2rem"}}>
            <button
              onClick={() => closeShowAdressPopup()}
              className="btn"
              style={{
                marginBottom: "0.7rem",
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
              הכתובת נכונה
            </button>
            <button
              onClick={() => {
                closeShowAdressPopup();
                OpenShowEditAdressPopup();
              }}
              className="btn btn-orange"
              style={{
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
              עריכה
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitAdressPopup;
